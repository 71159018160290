<template>
  <div class="full-height">
    <div fluid class="full-height full-width white-background">
      <div class="d-flex justify-start">
        <v-icon large dark class="pa-2 ml-3 my-2 brand-background border-radius"
          >mdi-cog-outline</v-icon
        >
        <v-card-title> Account Management </v-card-title>
      </div>
      <v-divider class="mx-4" />
      <v-tabs v-model="tab">
        <v-tabs-slider color="brandCyan"></v-tabs-slider>
        <v-tab :key="keys.company" v-if="showCompanyTab"> Company Info </v-tab>
        <v-tab :key="keys.customization" v-if="showCompanyTab">
          Customization
        </v-tab>
        <v-tab :key="keys.reporting" v-if="showCompanyTab"> Reporting </v-tab>
        <v-tab :key="keys.finch" v-if="showCompanyTab"> Integrations </v-tab>
        <v-tab :key="keys.procore" v-if="adminOrRhistler"> Procore </v-tab>
        <v-tab :key="keys.procoreKickoff" v-if="showProcoreKickoff">
          Procore Kickoff
        </v-tab>
        <v-tab :key="keys.presets" v-if="showPresetTab"> Presets </v-tab>
        <!-- <v-tab :key="keys.hierarchy" v-if="showHierarchyTab"> Hierarchy </v-tab> -->
        <v-tab :key="keys.documents"> Documents </v-tab>
        <v-tab :key="keys.roles"> Roles </v-tab>
        <v-tab :key="keys.clients" v-if="userProfile.clientId == 1">
          Client Management
        </v-tab>
        <v-tab :key="keys.cron" v-if="userProfile.clientId == 1">
          CRON Manager
        </v-tab>
        <v-tab :key="keys.robin" v-if="showRecommendationsTab">
          Robin Manager
        </v-tab>
        <v-tab :key="keys.kyb" v-if="showMarqetaKYBTab"> KYB Manager </v-tab>
      </v-tabs>
      <v-divider />

      <v-tabs-items v-model="tab">
        <v-tab-item :key="keys.company" v-if="showCompanyTab">
          <v-card flat class="my-2 ml-3 mr-2">
            <v-row>
              <v-col cols="12" sm="12" md="3">
                <h2 class="text-left mb-3">Company Info</h2>
                <v-divider></v-divider>

                <p class="text-left mt-3">
                  Here you can update your company info and platform settings.
                </p>
                <div
                  class="text-left d-flex align-center"
                  v-if="formattedSignupLink"
                >
                  Custom signup link:
                  <v-btn
                    class="ml-1"
                    icon
                    small
                    @click="copySignupLink(formattedSignupLink)"
                  >
                    <v-icon small>mdi-link </v-icon>
                  </v-btn>
                </div>
                <div v-if="formattedSignupLink" class="text-left full-width">
                  <v-hover v-model="onboardingLinkHover">
                    <div class="">
                      <a @click="openSignupLink(formattedSignupLink)">{{
                        formattedSignupLink
                      }}</a>
                      <v-btn
                        v-if="onboardingLinkHover"
                        class="ml-1"
                        icon
                        small
                        @click="editSignupLink"
                      >
                        <v-icon small>mdi-pencil </v-icon>
                      </v-btn>
                    </div>
                  </v-hover>
                </div>
                <v-btn
                  v-if="!formattedSignupLink"
                  color="brandCyan"
                  class="white--text"
                  depressed
                  small
                  @click="editSignupLink"
                  >Generate custom signup link</v-btn
                >
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="9"
                lg="9"
                class="text-left full-width"
              >
                <v-card
                  flat
                  rounded="0"
                  class="full-width box-border mt-4 px-8"
                >
                  <p class="text-h6 mt-6">Company Details</p>
                  <v-form v-model="validForm1">
                    <!-- <div class="full-width"> -->
                    <v-row>
                      <div
                        class="d-flex align-center flex-wrap mt-3"
                        :class="
                          $vuetify.breakpoint.xs || $vuetify.breakpoint.xs
                            ? 'justify-center'
                            : ''
                        "
                      >
                        <v-img
                          v-if="
                            userProfile.Client.companyPicUrl != null ||
                              newCompanyPicTemp
                          "
                          :src="
                            newCompanyPicTemp
                              ? newCompanyPicTemp
                              : userProfile.Client.companyPicUrl
                          "
                          max-height="150"
                          height="auto"
                          max-width="200px"
                          contain
                          @click="displayImageSelector(false)"
                          @drop.prevent="dragAndDropImageFileChanged"
                          @dragover.prevent
                          class="cursor-pointer ma-3 box-border"
                        />
                        <v-img
                          v-else
                          src="@/assets/placeholder_image.png"
                          max-height="150"
                          height="auto"
                          max-width="200px"
                          contain
                          @click="displayImageSelector(false)"
                          @drop.prevent="dragAndDropImageFileChanged"
                          @dragover.prevent
                          class="cursor-pointer ma-3 box-border"
                        />
                        <v-btn
                          class="mx-3"
                          medium
                          right
                          text
                          color="brandCyan"
                          @click="displayImageSelector(false)"
                        >
                          Upload Logo
                        </v-btn>
                        <v-btn
                          v-if="
                            userProfile.Client.companyPicUrl ||
                              newCompanyPicTemp
                          "
                          class="mr-2"
                          medium
                          right
                          text
                          color="black"
                          @click="deleteCompanyPicture"
                        >
                          Delete Logo
                        </v-btn>
                      </div>

                      <input
                        ref="uploader"
                        class="d-none"
                        type="file"
                        accept="image/*"
                        @change="imageFileChanged"
                      />
                    </v-row>
                    <!-- </div> -->
                    <v-row
                      ><v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-text-field
                          dense
                          class="mt-4"
                          v-model="userProfile.Client.clientName"
                          label="Company Name"
                          :rules="[v => !!v || 'Company name is required']"
                        ></v-text-field></v-col
                      ><v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-text-field
                          dense
                          class="mt-4 full-width"
                          v-model="userProfile.Client.emailDomain"
                          label="Company Email Domain"
                          :rules="[
                            v =>
                              !!(!v || (v && !v.includes('@'))) ||
                              'Email domain should not include the @ symbol. Example: robin@gmail.com would be entered as gmail.com'
                          ]"
                        ></v-text-field></v-col
                    ></v-row>
                    <v-row
                      ><v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-select
                          v-model="userProfile.Client.industry"
                          :items="industries"
                          label="Industry"
                          item-value="value"
                          item-text="name"
                          class="mt-4"
                        ></v-select
                      ></v-col>
                      <!-- <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-select
                          class="mt-4"
                          v-model="userProfile.Client.primaryUseCase"
                          label="Primary use of Whistle"
                          :items="onboardingLanguageOptions"
                          item-value="value"
                          item-text="label"
                        ></v-select></v-col
                    > -->
                    </v-row>
                    <!-- <div class="justify-space-between d-flex full-width"> -->

                    <!-- </div> -->

                    <v-row justify="center" class="pb-4">
                      <v-col cols="3">
                        <div class="d-flex justify-center">
                          <v-btn
                            medium
                            right
                            color="brandCyan"
                            class="white--text"
                            depressed
                            :disabled="!validForm1"
                            :loading="submittingChanges"
                            @click="saveCompanySettings(null)"
                          >
                            Save Changes
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="keys.customization" v-if="showCompanyTab">
          <v-card flat class="my-2 ml-3 mr-2">
            <v-row>
              <v-col cols="12" sm="12" md="3">
                <h2 class="text-left mb-3">Customization</h2>
                <v-divider></v-divider>

                <p class="text-left mt-3">
                  Here you can update platform settings for your company to
                  fully customize Whistle.
                </p>
                <div
                  class="text-left d-flex align-center"
                  v-if="formattedSignupLink"
                >
                  Custom signup link:
                  <v-btn
                    class="ml-1"
                    icon
                    small
                    @click="copySignupLink(formattedSignupLink)"
                  >
                    <v-icon small>mdi-link </v-icon>
                  </v-btn>
                </div>
                <div v-if="formattedSignupLink" class="text-left full-width">
                  <v-hover v-model="onboardingLinkHover">
                    <div class="">
                      <a @click="openSignupLink(formattedSignupLink)">{{
                        formattedSignupLink
                      }}</a>
                      <v-btn
                        v-if="onboardingLinkHover"
                        class="ml-1"
                        icon
                        small
                        @click="editSignupLink"
                      >
                        <v-icon small>mdi-pencil </v-icon>
                      </v-btn>
                    </div>
                  </v-hover>
                </div>
                <v-btn
                  v-if="!formattedSignupLink"
                  color="brandCyan"
                  class="white--text"
                  depressed
                  small
                  @click="editSignupLink"
                  >Generate custom signup link</v-btn
                >
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="9"
                lg="9"
                class="text-left full-width"
              >
                <v-card
                  flat
                  rounded="0"
                  class="full-width box-border mt-4 px-8"
                >
                  <p class="text-h6 mt-6">Personalization</p>

                  <v-form v-model="validForm2">
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-select
                          dense
                          v-model="userProfile.Client.initialRouteOnLoad"
                          :items="routablePagesOnLoad"
                          label="Default page"
                          no-data-text="No pages available"
                          item-value="value"
                          item-text="label"
                          class="mt-4"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-select
                          dense
                          class="mt-4"
                          v-model="userProfile.Client.onboardingLanguage"
                          label="Language used in account setup for new users"
                          :items="onboardingLanguageOptions"
                          item-value="value"
                          item-text="label"
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row
                      ><v-col
                        cols="12"
                        sm="12"
                        md="6"
                        lg="6"
                        xl="6"
                        class="d-flex align-center"
                      >
                        <v-text-field
                          dense
                          class="mt-4"
                          v-model="userProfile.Client.primaryColor"
                          label="Primary brand color (in hexadecimal)"
                          :rules="[
                            v =>
                              !!(!v || (v && v.startsWith('#'))) ||
                              'Your hex color value should start with #',
                            v =>
                              !!(!v || (v && v.length == 7)) ||
                              'Your hex color value should be 6 characters'
                          ]"
                        ></v-text-field>
                        <input
                          type="color"
                          id="html5colorpicker"
                          v-model="userProfile.Client.primaryColor"
                          style="width: 15%; margin-left: 5px"/></v-col
                      ><v-col
                        cols="12"
                        sm="12"
                        md="6"
                        lg="6"
                        xl="6"
                        class="d-flex align-center"
                      >
                        <v-text-field
                          dense
                          class="mt-4"
                          v-model="userProfile.Client.secondaryColor"
                          label="Secondary brand color (in hexadecimal)"
                          :rules="[
                            v =>
                              !!(!v || (v && v.startsWith('#'))) ||
                              'Your hex color value should start with #',
                            v =>
                              !!(!v || (v && v.length == 7)) ||
                              'Your hex color value should be 6 characters'
                          ]"
                        ></v-text-field>
                        <input
                          type="color"
                          id="html5colorpicker"
                          v-model="userProfile.Client.secondaryColor"
                          style="width: 15%; margin-left: 5px"/></v-col
                    ></v-row>
                    <v-row v-if="displayAnnualPaymentCap">
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-text-field
                          dense
                          class="mt-4"
                          prefix="$"
                          type="number"
                          v-model="userProfile.Client.annualPaymentCap"
                          label="Annual payment cap per user (by calendar year)"
                          :rules="formRules.optionalAmount(false)"
                        ></v-text-field>
                      </v-col>
                      <!-- <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-select
                          class="mt-4"
                          v-model="userProfile.Client.onboardingLanguage"
                          label="Language used in account setup for new users"
                          :items="onboardingLanguageOptions"
                          item-value="value"
                          item-text="label"
                        ></v-select>
                      </v-col> -->
                    </v-row>
                    <!-- <v-row
                      ><v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-switch
                          v-model="userProfile.Client.displayUniversalLearning"
                          label="Display Default Whistle Training Content in Library"
                          color="brandCyan"
                        ></v-switch>
                      </v-col>
                    </v-row> -->
                    <v-row class="pb-4"
                      ><v-col
                        cols="12"
                        sm="12"
                        md="6"
                        lg="6"
                        xl="6"
                        class="py-0"
                      >
                        <v-checkbox
                          label="Display Default Whistle Training Content in Library"
                          v-model="userProfile.Client.displayUniversalLearning"
                          color="brandCyan"
                          hide-details
                        /> </v-col
                      ><v-col
                        cols="12"
                        sm="12"
                        md="6"
                        lg="6"
                        xl="6"
                        class="py-0"
                      >
                        <div class="d-flex">
                          <v-checkbox
                            label="Enable Magic Links"
                            v-model="userProfile.Client.magicLinksEnabled"
                            color="brandCyan"
                            hide-details
                          />
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-on="on" v-bind="attrs" class="ml-3 mt-4"
                                >mdi-help-circle</v-icon
                              >
                            </template>
                            <span
                              >Magic links help make it easier for payment
                              recipients to access their new funds without
                              logging in.<br />Note: These links only work once
                              before expiring.</span
                            >
                          </v-tooltip>
                        </div></v-col
                      ></v-row
                    >
                    <v-row
                      ><v-col
                        cols="12"
                        sm="12"
                        md="6"
                        lg="6"
                        xl="6"
                        class="py-0"
                      >
                        <v-checkbox
                          label="Display Activity Page"
                          v-model="userProfile.Client.displayActivityPage"
                          color="brandCyan"
                          hide-details
                          class="pb-4"
                        />
                        <v-checkbox
                          label="Display Wallet Page"
                          v-model="userProfile.Client.displayWalletPage"
                          color="brandCyan"
                          hide-details
                          class="pb-4"
                        /> </v-col
                      ><v-col
                        cols="12"
                        sm="12"
                        md="6"
                        lg="6"
                        xl="6"
                        class="py-0"
                      >
                        <v-checkbox
                          label="Display Program Page"
                          v-model="userProfile.Client.displayProgramPage"
                          color="brandCyan"
                          hide-details
                          class="pb-4"/>
                        <v-checkbox
                          label="Display Library Page"
                          v-model="userProfile.Client.displayLibraryPage"
                          color="brandCyan"
                          hide-details
                          class="pb-4"/></v-col
                    ></v-row>

                    <v-row justify="center" class="pb-4">
                      <v-col cols="3">
                        <div class="d-flex justify-center">
                          <v-btn
                            medium
                            right
                            color="brandCyan"
                            depressed
                            class="white--text"
                            :disabled="!validForm2"
                            :loading="submittingChanges"
                            @click="saveCompanySettings(null)"
                          >
                            Save Changes
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-form></v-card
                >
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="keys.reporting" v-if="showCompanyTab">
          <v-card flat class="my-2 ml-3 mr-2">
            <v-row>
              <v-col cols="12" sm="12" md="3">
                <h2 class="text-left mb-3">Reporting Settings</h2>
                <v-divider></v-divider>

                <p class="text-left mt-3">
                  Here you can customize your Whistle reports. <br />Note: Any
                  changes could take up to an hour to reflect in your reports.
                </p>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="9"
                lg="9"
                class="text-left full-width"
              >
                <v-card
                  flat
                  rounded="0"
                  class="full-width box-border mt-4 px-8"
                >
                  <p class="text-h6 mt-6">Reporting Customization</p>

                  <v-form v-model="validForm3">
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-select
                          v-model="settings.reporting.column1"
                          :items="customReportColumns"
                          label="Custom column 1"
                          item-value="value"
                          item-text="label"
                          class="mt-4"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-select
                          class="mt-4"
                          v-model="settings.reporting.column2"
                          label="Custom column 2"
                          :items="customReportColumns"
                          item-value="value"
                          item-text="label"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-select
                          v-model="settings.reporting.column3"
                          :items="customReportColumns"
                          label="Custom column 3"
                          item-value="value"
                          item-text="label"
                          class="mt-4"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-select
                          class="mt-4"
                          v-model="settings.reporting.column4"
                          label="Custom column 4"
                          :items="customReportColumns"
                          item-value="value"
                          item-text="label"
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row justify="center" class="pb-4">
                      <v-col cols="3">
                        <div class="d-flex justify-center">
                          <v-btn
                            medium
                            right
                            color="brandCyan"
                            depressed
                            class="white--text"
                            :disabled="!validForm3"
                            :loading="submittingChanges"
                            @click="saveReportingSettings"
                          >
                            Save Changes
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-form></v-card
                >
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="keys.finch" v-if="showCompanyTab">
          <integrations-manager
            :alert="alert"
            :get-providers="getFinchProviders"
            :finch-disconnect="finchDisconnect"
            :finch-connect="finchConnect"
            :finch-resync="finchResync"
          />
        </v-tab-item>
        <v-tab-item :key="keys.procore" v-if="adminOrRhistler">
          <procore-manager />
        </v-tab-item>
        <v-tab-item :key="keys.procoreKickoff" v-if="showProcoreKickoff">
          <ProcoreKickoffDebugger />
        </v-tab-item>
        <v-tab-item :key="keys.presets" v-if="showPresetTab">
          <PaymentPresetManager />
        </v-tab-item>
        <!-- <v-tab-item :key="keys.hierarchy" v-if="showHierarchyTab">
          <v-card flat>
            <Hierarchy />
          </v-card>
        </v-tab-item> -->
        <v-tab-item :key="keys.documents">
          <v-card flat class="my-2 ml-3 mr-2">
            <v-row>
              <v-col cols="12" sm="12" md="3">
                <h2 class="text-left mb-3">Documents</h2>
                <v-divider></v-divider>
                <div class="d-flex flex-column align-end mt-5">
                  <v-btn
                    text
                    color="brandCyan"
                    @click="downloadDocument({ id: 'privacy_gdpr' })"
                    >Whistle Privacy Policy</v-btn
                  >
                  <v-btn
                    text
                    color="brandCyan"
                    @click="downloadDocument({ id: 'terms' })"
                    >Whistle Terms of Service</v-btn
                  >
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="9"
                lg="9"
                class="text-left d-flex flex-wrap full-width"
              >
                <v-card
                  flat
                  rounded="0"
                  class="d-flex flex-column flex-wrap full-width box-border mt-4"
                >
                  <v-row class="mt-4 mx-4">
                    <v-col
                      ><v-card-title class="font-weight-bold"
                        >Account Documents</v-card-title
                      ><v-card-subtitle
                        >A central place to view and download helpful Whistle
                        documents.</v-card-subtitle
                      ></v-col
                    >
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-data-table
                        :headers="documentColumns"
                        :items="Documents"
                        item-key="id"
                        :items-per-page="10"
                        class="elevation-0 row-pointer table mx-5"
                        loading-text="Loading... Please wait"
                        @click:row="
                          if ($event.type === 'API') {
                            routeToApiDocs($event.id);
                          } else {
                            downloadDocument($event);
                          }
                        "
                      >
                      </v-data-table
                    ></v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="keys.roles">
          <v-card flat class="my-2 ml-3 mr-2">
            <v-row>
              <v-col cols="12" sm="12" md="3">
                <h2 class="text-left mb-3">Roles</h2>
                <v-divider></v-divider>
                <div class="d-flex flex-column align-end mt-5">
                  <!-- <v-btn
                    text
                    color="primary"
                    @click="downloadDocument({ id: 'privacy_gdpr' })"
                    >Whistle Privacy Policy</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="downloadDocument({ id: 'terms' })"
                    >Whistle Terms of Service</v-btn
                  > -->
                  <v-btn
                    v-if="showMigrationButton"
                    color="brandCyan"
                    :loading="auth0Loading"
                    @click="migrate"
                    text
                    >Migrate Roles</v-btn
                  >
                  <v-btn
                    v-if="userProfile.clientId == 1"
                    color="brandCyan"
                    @click="routeToACL"
                    text
                    >Whistle ACL</v-btn
                  >
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="9"
                lg="9"
                class="text-left d-flex flex-wrap full-width"
              >
                <v-card
                  flat
                  rounded="0"
                  class="d-flex flex-wrap full-width box-border mt-4"
                >
                  <v-row class="mt-4 mx-4">
                    <v-col
                      ><v-card-title class="font-weight-bold"
                        >Role Management</v-card-title
                      ><v-card-subtitle
                        >A central place to view all roles within Whistle and
                        read more about what they have access
                        to.</v-card-subtitle
                      ></v-col
                    >
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-data-table
                        :headers="roleColumns"
                        :items="userRoles"
                        item-key="id"
                        :items-per-page="-1"
                        class="elevation-0 row-pointer table mx-5"
                        loading-text="Loading... Please wait"
                        hide-default-footer
                      >
                      </v-data-table
                    ></v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>

        <v-tab-item :key="keys.clients" v-if="userProfile.clientId == 1">
          <v-card flat class="my-2 ml-3 mr-2">
            <v-row>
              <v-col sm="12" md="3">
                <h2 class="text-left mb-3">Client Management</h2>
                <v-divider></v-divider>
                <p class="text-left mt-3">
                  Welcome to Whistle's client management system
                </p>
                <p class="text-left">
                  Here you can manage clients, update their info, and create new
                  clients when needed. Client deletion is not supported - this
                  could cause too many problems.
                </p>
              </v-col>
              <v-col sm="12" md="9">
                <div
                  class="d-flex justify-space-between align-center mr-2 mb-3"
                >
                  <!-- Filter and Sort -->
                  <div class="d-flex">
                    <v-btn text color="brandCyan" to="accountManagement"
                      >Clients</v-btn
                    >
                    <v-menu
                      v-model="clientFilters.showMenu"
                      offset-y
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          color="brandCyan"
                          class="ml-2"
                          v-bind="attrs"
                          v-on="on"
                          >Filter</v-btn
                        >
                      </template>
                      <v-list>
                        <v-list-item>
                          <!-- <template v-slot:default="{ active }"> -->
                          <v-list-item-action>
                            <v-checkbox
                              color="brandCyan"
                              v-model="clientFilters.clientCreation"
                            ></v-checkbox>
                          </v-list-item-action>
                          <!-- <v-list-item-icon class="mr-3">
                            <v-icon>mdi-brain</v-icon>
                          </v-list-item-icon> -->

                          <v-list-item-content class="text-left">
                            <v-list-item-title
                              >Client Creation</v-list-item-title
                            >
                          </v-list-item-content>
                          <v-list-item-action
                            ><v-btn
                              text
                              small
                              color="brandCyan"
                              @click="loadOneClientFilter('clientCreation')"
                              >Only</v-btn
                            ></v-list-item-action
                          >
                          <!-- </template> -->
                        </v-list-item>
                        <v-list-item>
                          <!-- <template v-slot:default="{ active }"> -->
                          <v-list-item-action>
                            <v-checkbox
                              color="brandCyan"
                              v-model="clientFilters.emailVerified"
                            ></v-checkbox>
                          </v-list-item-action>

                          <v-list-item-content class="text-left">
                            <v-list-item-title
                              >Verified Email</v-list-item-title
                            >
                          </v-list-item-content>
                          <v-list-item-action
                            ><v-btn
                              text
                              small
                              color="brandCyan"
                              @click="loadOneClientFilter('emailVerified')"
                              >Only</v-btn
                            ></v-list-item-action
                          >
                          <!-- </template> -->
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox
                              color="brandCyan"
                              v-model="clientFilters.blacklist"
                            ></v-checkbox>
                          </v-list-item-action>

                          <v-list-item-content class="text-left">
                            <v-list-item-title>Blacklist</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action
                            ><v-btn
                              text
                              small
                              color="brandCyan"
                              @click="loadOneClientFilter('blacklist')"
                              >Only</v-btn
                            ></v-list-item-action
                          >
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox
                              color="brandCyan"
                              v-model="clientFilters.agreements"
                            ></v-checkbox>
                          </v-list-item-action>

                          <v-list-item-content class="text-left">
                            <v-list-item-title>Signed MSA</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action
                            ><v-btn
                              text
                              small
                              color="brandCyan"
                              @click="loadOneClientFilter('agreements')"
                              >Only</v-btn
                            ></v-list-item-action
                          >
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox
                              color="brandCyan"
                              v-model="clientFilters.kybPending"
                            ></v-checkbox>
                          </v-list-item-action>

                          <v-list-item-content class="text-left">
                            <v-list-item-title>Pending KYB</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action
                            ><v-btn
                              text
                              small
                              color="brandCyan"
                              @click="loadOneClientFilter('kybPending')"
                              >Only</v-btn
                            ></v-list-item-action
                          >
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox
                              color="brandCyan"
                              v-model="clientFilters.kybSuccess"
                            ></v-checkbox>
                          </v-list-item-action>

                          <v-list-item-content class="text-left">
                            <v-list-item-title>Completed KYB</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action
                            ><v-btn
                              text
                              small
                              color="brandCyan"
                              @click="loadOneClientFilter('kybSuccess')"
                              >Only</v-btn
                            ></v-list-item-action
                          >
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox
                              color="brandCyan"
                              v-model="clientFilters.complete"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content class="text-left">
                            <v-list-item-title>Completed</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action
                            ><v-btn
                              text
                              small
                              color="brandCyan"
                              @click="loadOneClientFilter('complete')"
                              >Only</v-btn
                            ></v-list-item-action
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>

                  <v-text-field
                    outlined
                    placeholder="Search"
                    prepend-icon="mdi-magnify"
                    clearable
                    single-line
                    dense
                    hide-details
                    v-model="clientSearch"
                    class="shrink"
                  ></v-text-field>
                  <v-btn
                    v-if="permissions.includes('users:create:client')"
                    color="brandCyan"
                    depressed
                    class="mr-3 white--text"
                    @click="loadClientForm"
                    >Add Client</v-btn
                  >
                </div>
                <div>
                  <v-data-table
                    :headers="columns"
                    :items="visibleClients"
                    item-key="clientId"
                    :items-per-page="10"
                    :loading="loadingClients"
                    class="elevation-0 row-pointer table box-border"
                    loading-text="Loading... Please wait"
                    no-data-text="No clients available"
                    no-results-text="No clients found"
                    :search="clientSearch"
                    sort-by="clientId"
                    :sort-desc="true"
                    must-sort
                    @click:row="loadEditClientForm"
                  >
                    <template v-slot:[`item.onboardingStatus`]="{ item }">
                      <span>{{
                        onboardingStatus[item.onboardingStatus] ||
                          item.onboardingStatus
                      }}</span>
                    </template>
                    <!-- <template v-slot:[`item.onboardingDetails`]="{ item }">
                      <v-btn
                        color="primary"
                        @click.native.stop=""
                        v-if="
                          item.onboardingStatus !== 'COMPLETE' &&
                            item.onboardingStatus !== null
                        "
                        >Show</v-btn
                      >
                    </template> -->
                  </v-data-table>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="keys.cron" v-if="userProfile.clientId == 1">
          <v-card flat class="my-2 ml-3 mr-2">
            <v-row>
              <v-col sm="12" md="3">
                <h2 class="text-left mb-3">CRON Manager</h2>
                <v-divider></v-divider>
                <p class="text-left mt-3">Welcome to Whistle's CRON manager.</p>
                <p class="text-left">
                  Here you can view the recurring tasks on the Whistle platform
                  and trigger them manually if needed.
                </p>
              </v-col>
              <v-col sm="12" md="9">
                <div
                  class="d-flex justify-space-between align-center mr-2 mb-3"
                >
                  <v-btn text color="brandCyan" to="accountManagement"
                    >Tasks</v-btn
                  >
                  <v-text-field
                    outlined
                    placeholder="Search"
                    prepend-icon="mdi-magnify"
                    clearable
                    single-line
                    dense
                    hide-details
                    v-model="clientSearch"
                    class="shrink"
                  ></v-text-field>
                  <!-- <v-btn
                    color="primary"
                    class="mr-3"
                    @click="resetClientForm(true)"
                    >Add Client</v-btn
                  > -->
                </div>
                <div>
                  <v-data-table
                    :headers="cronColumns"
                    :items="tasks"
                    item-key="endpoint"
                    :items-per-page="-1"
                    class="elevation-0 row-pointer table box-border"
                    loading-text="Loading... Please wait"
                    :search="cronSearch"
                  >
                    <template v-slot:[`item.button`]="{ item }">
                      <v-btn
                        color="brandCyan"
                        class="white--text"
                        depressed
                        @click="runCRON(item.endpoint)"
                        :loading="runningCRON == item.endpoint"
                        >Run</v-btn
                      >
                    </template>
                  </v-data-table>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="keys.robin" v-if="showRecommendationsTab">
          <RecommendationManager
        /></v-tab-item>

        <v-tab-item :key="keys.kyb" v-if="showMarqetaKYBTab">
          <MarqetaKYBManager
        /></v-tab-item>
      </v-tabs-items>
    </div>
    <!-- Nav drawer for adding clients -->
    <v-navigation-drawer
      v-model="createClientWidget"
      temporary
      fixed
      right
      width="500"
    >
      <ClientCreatorWidget
        v-if="createClientWidget"
        :clientToEdit="adminClientToEdit"
        @close="createClientWidget = false"
        @get-clients="getClients"
      />
    </v-navigation-drawer>
    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>

    <!-- Dialog used for displaying copied url snackbar. -->
    <v-snackbar
      v-model="onboardingSnackbar"
      :timeout="6000"
      :multi-line="true"
      :v-html="onboardingSnackbarText"
    >
      {{ onboardingSnackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="onboardingSnackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Dialog for editing a client's onboarding link -->
    <v-dialog v-model="dialog.onboardingToken" width="500" class="dialog">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-2 mb-5">
          <v-card-title class="word-break text-left exit-warning-text">
            {{
              userProfile.Client && !userProfile.Client.onboardingToken
                ? "Create your custom signup link"
                : "Edit your custom signup link"
            }}
          </v-card-title>
        </div>
        <v-form
          v-model="form.validOnboardingLink"
          onSubmit="return false;"
          class="full-width"
        >
          <v-card-text class="pt-0 full-width text-left">
            <p v-if="error.onboardingToken" class="red--text text-caption mb-2">
              {{ error.onboardingToken }}
            </p>
            <v-text-field
              label="Custom link"
              dense
              outlined
              placeholder="e.g. dunder-mifflin"
              v-model="client.onboardingToken"
              :rules="[
                v =>
                  !!(!v || (v && v.length < 128)) ||
                  'Your link should be shorter',
                v =>
                  !!(!v || (v && v.length > 5)) ||
                  'The link should be at least 6 characters long'
              ]"
              class="input-box mx-auto"
              @keyup.enter="
                saveCompanySettings({
                  onboardingToken: client.onboardingToken
                })
              "
            ></v-text-field>
            <div class="full-width text-left">
              <p :class="{ hidden: !formattedSignupLinkEditor }" class="mb-1">
                New link: <b>{{ formattedSignupLinkEditor }}</b>
              </p>
              <span class="grey--text"
                >Note: Your link may be altered to remove unapproved
                characters</span
              >
            </div>
          </v-card-text>
        </v-form>

        <v-card-actions class="mx-12 d-flex justify-center">
          <v-btn
            class="mr-4"
            color="brandCyan"
            outlined
            width="130"
            @click="
              (dialog.onboardingToken = false), (client.onboardingToken = null)
            "
            >Cancel</v-btn
          >
          <v-btn
            class="ml-4 white--text"
            color="brandCyan"
            width="130"
            depressed
            @click="
              saveCompanySettings({ onboardingToken: client.onboardingToken })
            "
            :disabled="!form.validOnboardingLink"
            >Done</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Image Cropping dialog -->
    <v-dialog persistent v-model="dialogImageCropper" width="600">
      <v-card rounded="0" class="px-12">
        <div class="d-flex align-center">
          <v-icon class="mr-2">mdi-camera</v-icon>
          <v-card-title class="word-break px-0 mx-0">
            Please crop the image if necessary.
          </v-card-title>
        </div>
        <div class="d-flex align-center"></div>
        <cropper ref="cropper" :src="imageUrlToCrop" :stencil-props="{}" />
        <v-alert
          dense
          outlined
          v-if="!uploadImageIsPNG"
          type="error"
          class="mt-4"
        >
          To avoid display issues we suggest a PNG logo file
        </v-alert>
        <v-card-actions class="pt-4">
          <v-spacer></v-spacer>
          <v-btn text @click="closeImageCropDialog(false)">Cancel</v-btn>
          <v-btn
            text
            color="brandCyan"
            @click="closeImageCropDialog(true)"
            :loading="isSelectingCompanyPic"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDialog" width="512">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 py-4">
          {{ dialogOpts.title }}
        </v-card-title>

        <v-card-text class="py-4">
          <strong>{{ dialogOpts.body }}</strong>
        </v-card-text>

        <!-- <v-divider></v-divider> -->

        <v-card-actions class="pt-3 pb-5">
          <v-spacer></v-spacer>
          <v-btn
            v-if="dialogOpts.cancellable"
            color="brandCyan"
            width="130"
            outlined
            depressed
            @click="showDialog = false"
            class="mr-3"
          >
            Cancel
          </v-btn>
          <v-btn
            :color="dialogOpts.okColor || 'brandCyan'"
            width="130"
            :class="
              (dialogOpts.okColor || 'brandCyan') == 'brandCyan'
                ? 'white--text'
                : ''
            "
            depressed
            @click="dialogOpts.okAction"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import HierarchyService from "@/services/HierarchyService.js";
// import Hierarchy from "@/components/Hierarchy.vue";
import RecommendationManager from "@/components/RecommendationManager.vue";
import MarqetaKYBManager from "@/components/MarqetaKYBManager.vue";
import LoadingDialog from "@/components/loadingDialog.vue";
import Auth0Service from "@/services/Auth0Service.js";
import IntegrationsManager from "@/components/account-management/Integrations.vue";
import ProcoreManager from "@/components/account-management/Procore.vue";
import ProcoreKickoffDebugger from "@/components/account-management/ProcoreKickoff.vue";
import PaymentPresetManager from "@/components/account-management/PaymentPresets.vue";
import ClientCreatorWidget from "@/components/account-management/ClientCreatorWidget.vue";

import UserService from "@/services/UserService";
import GroupService from "@/services/GroupService";
import ContentService from "@/services/ContentService";
import CommunicationService from "@/services/CommunicationService";
import ProgramService from "@/services/ProgramService";
// import BudgetService from "@/services/BudgetService";
import RobinService from "@/services/RobinService";
import WalletService from "@/services/WalletService";
import OnboardingService from "@/services/OnboardingService";
import axios from "axios";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import {
  // editorToolbar,
  industries,
  // editorOptions,
  formRules,
  userColumns
} from "@/shared_data/data";

import { mapState } from "vuex";

export default {
  name: "AccountManagement",
  title: "Whistle | Account Management",
  components: {
    LoadingDialog,
    // Hierarchy,
    RecommendationManager,
    MarqetaKYBManager,
    // VueEditor,
    ProcoreManager,
    IntegrationsManager,
    PaymentPresetManager,
    ClientCreatorWidget,
    Cropper,
    ProcoreKickoffDebugger
  },
  // watch: {
  //   tab(n, o) {
  //     alert("tab changed " + n + " " + o);
  //   },
  // },Z
  data() {
    return {
      keys: {
        company: 0,
        customization: 1,
        reporting: 2,
        finch: 3,
        procore: 4,
        procoreKickoff: 5,
        presets: 6,
        // hierarchy: 5,
        documents: 7,
        roles: 8,
        clients: 9,
        cron: 10,
        robin: 11,
        kyb: 12
      },
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      submittingChanges: false,
      isSelectingCompanyPic: false,
      validForm1: false,
      validForm2: false,
      validForm3: false,
      dialogImageCropper: false,
      imageUrlToCrop: null,
      imageUrlFileName: null,
      newCompanyPicFile: null,
      newCompanyPicTemp: null,
      // editorToolbar: editorToolbar,
      showDialog: false,

      dialogOpts: {
        title: "",
        body: ""
      },
      formRules,
      tab: 0,
      tabMapper: {
        "company-info": "Company Info",
        customization: "Customization",
        reporting: "Reporting",
        integrations: "Integrations",
        presets: "Presets",
        hierarchy: "Hierarchy",
        documents: "Documents",
        roles: "Roles",
        procore: "Procore",
        "procore-kickoff": "Procore Kickoff",
        "client-management": "Client Management",
        "cron-manager": "CRON Manager",
        "robin-manager": "Robin Manager",
        "kyb-manager": "KYB Manager"
      },
      stepper1: 1,
      stepper2: 1,
      stepper3: 3,
      columns: [
        {
          text: "Client ID",
          align: "center",
          value: "clientId",
          width: "20%"
        },
        {
          text: "Client Name",
          align: "start",
          value: "clientName",
          width: "40%"
        },
        {
          text: "Onboarding status",
          align: "start",
          value: "onboardingStatus",
          width: "40%"
        }
        // {
        //   text: "Onboarding details",
        //   align: "start",
        //   value: "onboardingDetails",
        //   width: "20%",
        // },
      ],
      onboardingStatus: {
        CLIENT_CREATION: "Client Created",
        BLACKLIST: "Blacklisted",
        COMPLETE: "Completed",
        null: "Completed",
        AGREEMENTS: "Signed MSA",
        KYB_SUCCESS: "Passed KYB",
        KYB_PENDING: "Pending KYB",
        EMAIL_VERIFIED: "Verified Email"
      },
      clientFilters: {
        showMenu: false,
        clientCreation: true,
        emailVerified: true,
        blacklist: true,
        agreements: true,
        kybPending: true,
        kybSuccess: true,
        complete: true
      },
      loadingBlacklist: false,
      createClientWidget: false,
      clientSearch: null,
      uploadImageIsPNG: false,
      clientAdminImage: {
        file: null,
        imageUrl: null
      },
      loadingClients: false,
      clients: [],
      client: {
        valid: false,
        clientName: null,
        emailDomain: null,
        companyPicUrl: null,
        initialRouteOnLoad: "wallet",
        onboardingLanguage: "PAYMENTS",
        parentClientId: null,
        whitelabel: false,
        paid: false,
        physicalCardFee: "0",
        onboardingToken: null,
        magicLinksEnabled: true,
        displayUniversalLearning: true,
        displayMobilePopup: true,
        displayActivityPage: true,
        displayProgramPage: true,
        displayWalletPage: true,
        displayLibraryPage: true,
        reportColumn1: null,
        reportColumn2: null,
        reportColumn3: null,
        reportColumn4: null
      },
      adminClientToEdit: null,
      validClientForm: false,

      onboardingSnackbar: false,
      onboardingSnackbarText: "Copied URL to clipboard",
      onboardingLinkHover: false,
      dialog: {
        onboardingToken: false
      },
      form: {
        validOnboardingLink: false
      },
      error: { onboardingToken: null },
      onboardingLanguageOptions: [
        { label: "Payments", value: "PAYMENTS" },
        { label: "Learning", value: "LEARNING" }

        // { label: "News", value: "news" },
      ],
      documentColumns: [
        {
          text: "Type",
          align: "start",
          value: "type",
          width: "20%"
        },
        {
          text: "Document Name",
          align: "start",
          value: "name",
          width: "40%"
        },
        {
          text: "Description",
          align: "start",
          value: "description",
          width: "40%"
        }
      ],
      roleColumns: [
        {
          text: "Role Title",
          align: "start",
          value: "name",
          width: "30%"
        },
        {
          text: "Description",
          align: "start",
          value: "description",
          width: "70%"
        }
      ],
      userRoles: [
        {
          id: "1",
          name: "Participant",
          description:
            "Used for the everyday employee that doesn't need any administrative privileges."
        },
        {
          id: "2",
          name: "Report Viewer",
          description: "Allows access to the Reporting page and to all reports."
        },
        {
          id: "3",
          name: "Learning Editor",
          description:
            "Allows limited access to the learning builder, editing learning modules and uploading content."
        },
        {
          id: "4",
          name: "Learning Admin",
          description:
            "Gives full access to the learning builder and all pages within it, including deleting learning modules and the creation of incentives attached to modules."
        },
        {
          id: "5",
          name: "People Admin",
          description:
            "Gives full access to the People and Groups page for user management. Can't assign any role that is a higher level than the admin's highest role. Adding the HR Admin role allows them to assign all roles."
        },
        {
          id: "6",
          name: "Message Admin",
          description:
            "Gives access to the Communication page to send messages to users."
        },
        {
          id: "7",
          name: "Program Admin",
          description:
            "Gives full access to program creation and management inside the program builder."
        },
        {
          id: "8",
          name: "Payments Admin",
          description:
            "Gives access to the payments console for sending payments to users. This role does not give permission to fund budgets."
        },
        {
          id: "9",
          name: "General Admin",
          description:
            "Can do almost anything on Whistle outside of sending payments & funding budgets."
        },
        {
          id: "10",
          name: "Payments + General Admin",
          description:
            "A General Admin with additional permissions to send payments."
        },
        {
          id: "11",
          name: "Finance Admin",
          description:
            "Has permission to fund budgets and provides access to the payments console for sending payments to users."
        },
        {
          id: "12",
          name: "HR Admin",
          description:
            "When combined with the People Admin role, the user can assign almost any role outside of Client Admin."
        },

        {
          id: "13",
          name: "Client Admin",
          description:
            "Has full access to the Whistle platform and all features within. Should be granted sparingly due to unrestricted access."
        }
      ],
      cronSearch: null,
      runningCRON: null,
      tasks: [
        {
          name: "Refresh groups",
          endpoint: "/refreshGroups"
        },
        {
          name: "Refresh video encoding",
          endpoint: "/content/encode/status"
        },
        {
          name: "Send new messages",
          endpoint: "/messgaes/cron/send"
        },
        // {
        //   name: "Refresh programs",
        //   endpoint: "/programs/cron/daily"
        // },
        {
          name: "Refresh Robin recommendations",
          endpoint: "/robin/cron/process"
        },
        // {
        //   name: "Send new provisional funds",
        //   endpoint: "/wallet/paymentRule/cron"
        // },
        // {
        //   name: "Clawback unused/expired provisional funds",
        //   endpoint: "/wallet/paymentRule/clawback/cron"
        // },
        // {
        //   name: "Send triggered platform messages",
        //   endpoint: "/messgaes/cron/marketing"
        // },
        {
          name: "Update email activity in Bigquery",
          endpoint: "/messgaes/cron/activity"
        },
        {
          name: "Update email activity that bounced in Bigquery",
          endpoint: "/messgaes/cron/bounces"
        },
        {
          name: "Update email activity that was blocked in Bigquery",
          endpoint: "/messgaes/cron/blocks"
        },
        {
          name: "Update email activity that went to spam in Bigquery",
          endpoint: "/messgaes/cron/spam"
        },

        // {
        //   name: "Send award orders CSV",
        //   endpoint: "/awardOrder/cron/email"
        // },

        {
          name: "Refreshes Stellar balances in Bigquery",
          endpoint: "/balance/cron/stellar"
        },
        {
          name: "Refreshes Whistle Card balances in Bigquery",
          endpoint: "/balance/cron/marqeta/users"
        },
        {
          name: "Refreshes Marqeta program balance in Bigquery",
          endpoint: "/balance/cron/marqeta/program"
        }
      ],
      cronColumns: [
        {
          text: "Task name",
          align: "start",
          value: "name",
          width: "80%"
        },
        {
          text: "",
          align: "center",
          value: "button",
          width: "20%"
        }
      ],
      auth0Loading: false,
      industries: industries,
      customReportColumns: userColumns,
      settings: {
        reporting: {
          column1: null,
          column2: null,
          column3: null,
          column4: null
        }
      }
    };
  },

  created() {
    if (this.$route.query && this.$route.query.tab) {
      var potentialTab = this.$route.query.tab;
      var tabIndex = this.tabs.findIndex(
        x => x == this.tabMapper[potentialTab]
      );
      if (tabIndex !== -1) this.tab = tabIndex;
    }
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has("code")) {
      const code = searchParams.get("code");
      this.finishFinchConnect(code);
    }
    if (
      this.userProfile &&
      this.userProfile.clientId == 1 &&
      this.permissions.includes("users:read:client")
    ) {
      this.getClients({ page: 0 });
    }

    if (this.userProfile && this.userProfile.Client) {
      this.settings.reporting.column1 = this.userProfile.Client.reportColumn1;
      this.settings.reporting.column2 = this.userProfile.Client.reportColumn2;
      this.settings.reporting.column3 = this.userProfile.Client.reportColumn3;
      this.settings.reporting.column4 = this.userProfile.Client.reportColumn4;
    }

    // this.client = JSON.parse(JSON.stringify(this?.userProfile?.Client));
  },
  methods: {
    alert({ title, body, okAction, okColor, cancellable }) {
      this.dialogOpts.title = title;
      this.dialogOpts.body = body;
      this.dialogOpts.cancellable = cancellable || false;
      this.dialogOpts.okAction = () => {
        okAction && okAction();
        this.showDialog = false;
      };
      this.dialogOpts.okColor = okColor || "brandCyan";
      this.showDialog = true;
    },
    async finchDisconnect(data) {
      const disconnectResult = await axios.post(
        process.env.VUE_APP_OAUTH_SVC_BASE_URI +
          "/finch/disconnect/" +
          data.clientSupplementalId,
        {
          token: this.$store.state.token
        },
        {
          validateStatus: () => true
        }
      );

      if (disconnectResult.status >= 200 && disconnectResult.status < 300) {
        return true;
      } else {
        console.log(disconnectResult);
        return false;
      }
    },
    finchConnect() {
      const params = {
        client_id: process.env.VUE_APP_TRYFINCH_CLIENT_ID,
        products: "employment directory individual",
        redirect_uri: process.env.VUE_APP_TRYFINCH_REDIRECT_URI,
        sandbox:
          process.env.VUE_APP_TRYFINCH_SANDBOX_MODE === "true"
            ? "true"
            : "false"
      };
      const url =
        process.env.VUE_APP_TRYFINCH_API_BASE_URI +
        "/authorize?" +
        new URLSearchParams(params);
      // alert("at this point we open " + url);
      window.location.href = url;
    },

    async finishFinchConnect(code) {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Syncing users...";
      this.loadingMessageLine1 =
        "Currently syncing users from your HRIS. Thanks for waiting!";
      this.loadingMessageLine2 = "";
      try {
        const connectResult = await axios.post(
          process.env.VUE_APP_OAUTH_SVC_BASE_URI + "/finch/connect",
          {
            code,
            token: this.$store.state.token
          },
          {
            validateStatus: () => true
          }
        );

        // if (connectResult.status >= 200 && connectResult.status < 300) {
        // } else {
        //   console.log(connectResult);
        // }
        const FINCH_TAB = this.tabs.indexOf("Integrations");

        this.tab = FINCH_TAB;
        const result = connectResult.data;
        this.alert({
          title: "HRIS Connection",
          body: result.error
            ? result.error
            : `Successfully connected ${result.provider.display_name}. Onboarding will complete shortly.`
        });
      } catch (e) {
        this.alert({
          title: "HRIS Connection",
          body: "Failed to establish HRIS connection. Please try again later."
        });
      } finally {
        this.showLoadingDialog = false;
      }
    },
    async finchResync(data) {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Syncing users...";
      this.loadingMessageLine1 =
        "Currently syncing users from your HRIS. Thanks for waiting!";
      this.loadingMessageLine2 = "";
      try {
        const refreshResult = await axios.post(
          process.env.VUE_APP_OAUTH_SVC_BASE_URI +
            "/finch/refresh/" +
            data.clientSupplementalId,
          {
            token: this.$store.state.token
          },
          {
            validateStatus: () => true
          }
        );

        // if (refreshResult.status >= 200 && refreshResult.status < 300) {
        // } else {
        //   console.log(refreshResult);
        // }
        const FINCH_TAB = this.tabs.indexOf("Integrations");

        this.tab = FINCH_TAB;
        const result = refreshResult.data;
        this.alert({
          title: "HRIS Connection",
          body: result.error
            ? result.error
            : `Re-sync scheduled. Users will finish updating shortly.`
        });
      } catch (e) {
        this.alert({
          title: "HRIS Connection",
          body: "Failed to establish HRIS connection. Please try again later."
        });
      } finally {
        this.showLoadingDialog = false;
      }
    },
    async getFinchProviders(code) {
      const finchProviderResult = await axios.post(
        process.env.VUE_APP_OAUTH_SVC_BASE_URI + "/finch/providers",
        {
          code,
          token: this.$store.state.token
        },
        {
          validateStatus: () => true
        }
      );

      if (
        finchProviderResult.status >= 200 &&
        finchProviderResult.status < 300
      ) {
        return finchProviderResult.data;
      } else {
        console.log(finchProviderResult);
      }
    },
    migrate() {
      this.auth0Loading = true;
      Auth0Service.migrate().then(() => {
        this.auth0Loading = false;
      });
    },
    routeToACL() {
      window.open(process.env.VUE_APP_ACCESS_CONTROL_URL, "_blank");
    },
    loadClientForm(widgetValue) {
      this.createClientWidget = widgetValue;
    },
    loadEditClientForm(client) {
      this.createClientWidget = true;
      let o = client;
      o.paid =
        (client.Subscriptions && client.Subscriptions.length > 0) || false;
      this.adminClientToEdit = o;
    },
    displayImageSelector(admin = false) {
      admin ? this.$refs.uploaderAdmin.click() : this.$refs.uploader.click();
    },
    closeImageCropDialog(submitPressed) {
      //submitPressed is so we can use one function for both dialog buttons
      //If they clicked submit, submitPressed is true
      //If they clicked cancel, submitPressed is false
      //var clientId = this.userProfile.clientId;
      if (submitPressed) {
        this.isSelectingCompanyPic = true;
        const { canvas } = this.$refs.cropper.getResult();
        canvas.toBlob(blob => {
          // Do something with blob: upload to a server, download and etc.
          var file = new File([blob], this.imageUrlFileName);
          if (this.tab == 0) {
            this.newCompanyPicFile = file;
            this.newCompanyPicTemp = canvas.toDataURL();
          } else {
            console.log("Setting new admin image");
            this.clientAdminImage = {
              file: file,
              imageUrl: canvas.toDataURL()
            };
          }
          this.isSelectingCompanyPic = false;
          //     //Regardless, we close dialog
          this.dialogImageCropper = false;
          this.imageURLToCrop = null;
          this.imageURLFileName = null;

          console.log("Uploading new profile picture: ", file);
          // UserService.updateClient(clientId, this.userProfile.Client, file)
          //   .then((imageResponse) => {
          //     console.log("Image upload response: ", imageResponse);
          //     // We got an image url back so now we update the user object with this image
          //     this.userProfile.Client.companyPicUrl = file;
          //     this.$store.state.userProfile.Client.companyPicUrl = file;
          //     this.isSelectingCompanyPic = false;
          //     //Regardless, we close dialog
          //     this.dialogImageCropper = false;
          //     this.imageURLToCrop = null;
          //     this.imageURLFileName = null;
          //   })
          //   .catch((error) => {
          //     console.log("Error uploading image: ", error);
          //     //Regardless, we close dialog
          //     this.dialogImageCropper = false;
          //     this.imageURLToCrop = null;
          //     this.imageURLFileName = null;
          //     this.isSelectingCompanyPic = false;
          //   });
        });
      } else {
        //Regardless, we close dialog
        this.dialogImageCropper = false;
        this.imageUrlToCrop = null;
        this.imageUrlFileName = null;
      }
    },
    imageFileChanged(e) {
      console.log("changed image");
      // this.user.isSelectingProfilePic = true;
      if (e.target.files.length > 0) {
        this.imageUrlToCrop = URL.createObjectURL(e.target.files[0]);
        this.imageUrlFileName = e.target.files[0].name;
        this.dialogImageCropper = true;

        if (e.target.files[0].type == "image/png") {
          this.uploadImageIsPNG = true;
        } else {
          this.uploadImageIsPNG = false;
        }
      } else {
        console.log("problem");
      }
    },
    dragAndDropImageFileChanged(e) {
      if (e.dataTransfer.files.length > 0) {
        // Load file into temp variable for cropping
        this.imageUrlToCrop = URL.createObjectURL(e.dataTransfer.files[0]);
        this.imageUrlFileName = e.dataTransfer.files[0].name;

        this.dialogImageCropper = true;

        if (e.dataTransfer.files[0].type == "image/png") {
          this.uploadImageIsPNG = true;
        } else {
          this.uploadImageIsPNG = false;
        }
      }
    },
    deleteCompanyPicture() {
      this.userProfile.Client.companyPicUrl = null;
      this.newCompanyPicTemp = null;
      this.newCompanyPicFile = null;
    },
    saveCompanySettings(obj = null) {
      if (
        obj &&
        obj.onboardingToken &&
        (obj.onboardingToken.length >= 128 || obj.onboardingToken.length < 6)
      )
        return;
      this.loadingHeaderMessage = "Updating settings";
      this.loadingMessageLine1 = "Please wait";
      this.loadingMessageLine2 = "This should just take a second!";
      this.showLoadingDialog = true;
      // Reset the error message if this is a retry
      this.error.onboardingToken = null;

      if (obj) {
        // Manually sent in obj used just for the onboarding signup token right now
        var client = obj;
      } else {
        if (this.tab == 0) {
          client = {
            clientName: this.userProfile.Client.clientName,
            emailDomain: this.userProfile.Client.emailDomain,
            industry: this.userProfile.Client.industry,
            companyPicUrl: this.newCompanyPicFile
              ? null
              : this.userProfile.Client.companyPicUrl
          };
        } else {
          client = {
            initialRouteOnLoad: this.userProfile.Client.initialRouteOnLoad,
            onboardingLanguage: this.userProfile.Client.onboardingLanguage,
            primaryColor: this.userProfile.Client.primaryColor,
            secondaryColor: this.userProfile.Client.secondaryColor,
            displayActivityPage: this.userProfile.Client.displayActivityPage,
            displayProgramPage: this.userProfile.Client.displayProgramPage,
            displayLibraryPage: this.userProfile.Client.displayLibraryPage,
            displayWalletPage: this.userProfile.Client.displayWalletPage,
            displayUniversalLearning: this.userProfile.Client
              .displayUniversalLearning,
            magicLinksEnabled: this.userProfile.Client.magicLinksEnabled,
            annualPaymentCap: this.userProfile.Client.annualPaymentCap,
            customProfileMessage: this.userProfile.Client.customProfileMessage
          };

          // If all pages are unchecked, then we by default show activity
          if (
            !client.displayActivityPage &&
            !client.displayProgramPage &&
            !client.displayLibraryPage &&
            !client.displayWalletPage
          )
            client.displayActivityPage = true;
          // If only one page is checked, we make that the route
          var checkedCounter = 0;
          if (client.displayActivityPage) checkedCounter++;
          if (client.displayProgramPage) checkedCounter++;
          if (client.displayWalletPage) checkedCounter++;
          if (client.displayLibraryPage) checkedCounter++;

          if (checkedCounter == 1) {
            // We must make initial route what the user checks
            if (client.displayActivityPage)
              client.initialRouteOnLoad = "activity";
            else if (client.displayProgramPage)
              client.initialRouteOnLoad = "programs";
            else if (client.displayWalletPage)
              client.initialRouteOnLoad = "wallet";
            else if (client.displayLibraryPage)
              client.initialRouteOnLoad = "learning";
          }

          // We want to make sure that the default route isn't unchecked/hidden
          if (client.initialRouteOnLoad) {
            if (
              client.initialRouteOnLoad == "activity" &&
              !client.displayActivityPage
            )
              client.initialRouteOnLoad = client.displayProgramPage
                ? "programs"
                : client.displayWalletPage
                ? "wallet"
                : "learning";
            else if (
              client.initialRouteOnLoad == "programs" &&
              !client.displayProgramPage
            )
              client.initialRouteOnLoad = client.displayActivityPage
                ? "activity"
                : client.displayWalletPage
                ? "wallet"
                : "learning";
            else if (
              client.initialRouteOnLoad == "wallet" &&
              !client.displayWalletPage
            )
              client.initialRouteOnLoad = client.displayActivityPage
                ? "activity"
                : client.displayProgramPage
                ? "programs"
                : "learning";
            else if (
              client.initialRouteOnLoad == "learning" &&
              !client.displayLibraryPage
            )
              client.initialRouteOnLoad = client.displayActivityPage
                ? "activity"
                : client.displayProgramPage
                ? "programs"
                : "wallet";
          }
        }
      }

      // The html color picker will contain an empty string if the user manually entered/deleted the hex color
      if (client.primaryColor == "") client.primaryColor = null;
      if (client.secondaryColor == "") client.secondaryColor = null;

      client.updatedBy = this.userProfile.userId;
      UserService.updateClient(
        this.userProfile.Client.clientId,
        client,
        this.newCompanyPicFile ? this.newCompanyPicFile : null
      )
        .then(response => {
          console.log(response);

          if (this.newCompanyPicTemp) {
            this.userProfile.Client.companyPicUrl = this.newCompanyPicTemp;
          }

          if (obj) {
            // If we manually passed in an object then we want to refresh the profile and user's client
            this.$store
              .dispatch("setUser", this.userProfile.userId)
              .then(() => {
                // Close the dialog after updating the user
                this.showLoadingDialog = false;
                this.dialog.onboardingToken = false;
                this.client.onboardingToken = null;
              });
          } else {
            this.showLoadingDialog = false;
          }
          //this.resetClientForm(false);
          //this.$store.dispatch("setClients");
        })
        .catch(() => {
          this.showLoadingDialog = false;
          // If there was an error and it was the onboarding token object passed in, show an error message
          if (obj)
            this.error.onboardingToken = "Please try a different signup link";
        });
    },
    saveReportingSettings() {
      this.loadingHeaderMessage = "Updating settings";
      this.loadingMessageLine1 = "Please wait";
      this.loadingMessageLine2 = "This should just take a second!";
      this.showLoadingDialog = true;

      let obj = {
        reportColumn1: this.settings.reporting.column1,
        reportColumn2: this.settings.reporting.column2,
        reportColumn3: this.settings.reporting.column3,
        reportColumn4: this.settings.reporting.column4
      };

      obj.updatedBy = this.userProfile.userId;
      UserService.updateClient(this.userProfile.Client.clientId, obj, null)
        .then(response => {
          console.log("Updated client", response);
          this.userProfile.Client.reportColumn1 = obj.reportColumn1;
          this.userProfile.Client.reportColumn2 = obj.reportColumn2;
          this.userProfile.Client.reportColumn3 = obj.reportColumn3;
          this.userProfile.Client.reportColumn4 = obj.reportColumn4;
        })
        .catch(err => {
          console.log("Error updating client ", err);
        })
        .finally(() => {
          this.showLoadingDialog = false;
        });
    },
    downloadDocument(row) {
      OnboardingService.downloadLegalDoc(row.id);
    },
    runCRON(endpoint) {
      this.runningCRON = endpoint;
      if (endpoint == "/refreshGroups") {
        GroupService.refreshGroupsV2().then(x => {
          console.log("Refresh group response: ", x);
          this.runningCRON = null;
        });
      } else if (endpoint == "/content/encode/status") {
        ContentService.refreshFileEncodingStatus().then(x => {
          console.log("Content encoding response: ", x);
          this.runningCRON = null;
        });
      } else if (endpoint == "/messgaes/cron/send") {
        console.log("CRON!");
        CommunicationService.sendMessageCRON().then(x => {
          console.log("Message CRON response: ", x);
          this.runningCRON = null;
        });
      } else if (endpoint == "/messgaes/cron/activity") {
        console.log("CRON!");
        CommunicationService.updateEmailActivityCRON().then(x => {
          console.log("Email Activity CRON response: ", x);
          this.runningCRON = null;
        });
      } else if (endpoint == "/messgaes/cron/bounces") {
        console.log("CRON!");
        CommunicationService.updateEmailBounceCRON().then(x => {
          console.log("Email Bounce CRON response: ", x);
          this.runningCRON = null;
        });
      } else if (endpoint == "/messgaes/cron/blocks") {
        console.log("CRON!");
        CommunicationService.updateEmailBlockCRON().then(x => {
          console.log("Email Block CRON response: ", x);
          this.runningCRON = null;
        });
      } else if (endpoint == "/messgaes/cron/spam") {
        console.log("CRON!");
        CommunicationService.updateEmailSpamCRON().then(x => {
          console.log("Email Spam CRON response: ", x);
          this.runningCRON = null;
        });
      } else if (endpoint == "/programs/cron/daily") {
        ProgramService.runProgramCRON().then(x => {
          console.log("Program CRON response: ", x);
          this.runningCRON = null;
        });
      }
      // else if (endpoint == "/awardOrder/cron/email") {
      //   BudgetService.runAwardOrderCRON().then(x => {
      //     console.log("Award Order CRON response: ", x);
      //     this.runningCRON = null;
      //   });
      // }
      else if (endpoint == "/robin/cron/process") {
        RobinService.processRecommendations().then(x => {
          console.log("Robin CRON response: ", x);
          this.runningCRON = null;
        });
      } else if (endpoint == "/balance/cron/stellar") {
        WalletService.updateReportingBalancesStellar().then(x => {
          console.log("Balance CRON response: ", x);
          this.runningCRON = null;
        });
      } else if (endpoint == "/balance/cron/marqeta/users") {
        WalletService.updateReportingBalancesMarqetaUsers().then(x => {
          console.log("Balance CRON response: ", x);
          this.runningCRON = null;
        });
      } else if (endpoint == "/balance/cron/marqeta/program") {
        WalletService.updateReportingBalancesProgramBalance().then(x => {
          console.log("Balance CRON response: ", x);
          this.runningCRON = null;
        });
      }
      // else if (endpoint == "/wallet/paymentRule/cron") {
      //   WalletService.runProvisionalRuleCRON().then(x => {
      //     console.log("Prov rule CRON response: ", x);
      //     this.runningCRON = null;
      //   });
      // }
      // else if (endpoint == "/wallet/paymentRule/clawback/cron") {
      //   WalletService.runClawbackCRON().then(x => {
      //     console.log("Rule clawback CRON response: ", x);
      //     this.runningCRON = null;
      //   });
      // }
    },
    loadOneClientFilter(filter) {
      this.clientFilters[filter] = true;
      Object.keys(this.clientFilters).forEach(key => {
        if (key != "showMenu" && filter !== key) {
          this.clientFilters[key] = false;
        }
      });
    },
    openSignupLink(link) {
      window.open(link, "_blank");
    },
    async copySignupLink(link) {
      await navigator.clipboard.writeText(link);
      // const el = document.createElement("textarea");
      // el.value = link;
      // el.setAttribute("readonly", "");
      // el.style.position = "absolute";
      // el.style.left = "-9999px";
      // document.body.appendChild(el);
      // const selected =
      //   document.getSelection().rangeCount > 0
      //     ? document.getSelection().getRangeAt(0)
      //     : false;
      // el.select();
      // document.execCommand("copy");
      // //document.body.removeChild(el);
      // if (selected) {
      //   document.getSelection().removeAllRanges();
      //   document.getSelection().addRange(selected);
      // }

      this.onboardingSnackbarText = "Copied URL to clipboard: " + link;
      this.onboardingSnackbar = true;
    },
    editSignupLink() {
      this.dialog.onboardingToken = true;
      this.client.onboardingToken = this.userProfile.Client.onboardingToken;
    },
    async getClients({ page, filter, clientId }) {
      let apiPage = page || 0;
      console.log("Calling getClientsV2 for page ", apiPage);
      try {
        let params = {
          limit: clientId ? 1 : 200,
          offset: apiPage * 200,
          filter: filter || `status = 'Active'`,
          // extract: "clientId,clientName",
          expand: "Subscription",
          order: "clientId ASC"
        };
        this.loadingClients = true;
        var response = await UserService.getClientsV2(params);
        console.log("Got clients ", response.result);
        var clients = response.result.rows.map(x => {
          var obj = x;
          obj.formattedName = x.clientId + " - " + x.clientName;
          obj.Subscriptions = obj.Subscriptions.filter(
            x => x.status == "Active"
          );
          return obj;
        });

        if (apiPage == 0 && !filter) {
          this.clients = clients;
        } else if (clientId) {
          // we have to replace an individual client in the array
          let clientIndex = this.clients.findIndex(x => x.clientId == clientId);
          console.log("Updating individual client at index ", clientIndex);
          console.log(clients.length);
          if (clientIndex != -1 && clients.length == 1) {
            this.$set(this.clients, clientIndex, clients[0]);
            // this.clients[clientIndex] = clients[0];
          }
        } else {
          this.clients = this.clients.concat(clients);
          // this.table.users = this.table.users.concat(rows);
        }
        if (
          Number(this.clients.length) < Number(response.result.count) &&
          !filter
        ) {
          console.log("GETTING MORE CLIENTS");
          apiPage = apiPage + 1;
          this.getClients({ page: apiPage });
          // fetchLoop(page, false);
        } else {
          this.loadingClients = false;
          console.log("We've reached the end of the client list");
        }
        // commit("SET_CLIENTS", clients);
      } catch (err) {
        console.log("Error getting clients ", err);
      }
      // }
    },
    routeToApiDocs(serviceName) {
      const url = `${process.env.VUE_APP_WHISTLE_API}/${serviceName}/docs/`;
      console.log("Opening Docs", { url, serviceName });
      window.open(url, "_blank");
    }
  },
  computed: {
    ...mapState(["permissions", "userProfile", "token", "roles"]),
    adminOrRhistler() {
      return (
        this.userProfile.clientId == 1 ||
        this.userProfile.businessEmail
          .toLowerCase()
          .indexOf("@wewhistle.com") !== -1
      );
    },
    tabs() {
      var arr = [];
      if (this.showCompanyTab) arr.push("Company Info");
      if (this.showCompanyTab) arr.push("Customization");
      if (this.showCompanyTab) arr.push("Reporting");
      if (this.showCompanyTab) arr.push("Integrations");
      if (this.adminOrRhistler) arr.push("Procore");
      if (this.showProcoreKickoff) arr.push("Procore Kickoff");
      if (this.showPresetTab) arr.push("Presets");
      // if (this.showHierarchyTab) arr.push("Hierarchy");
      arr.push("Documents");
      arr.push("Roles");
      if (this.userProfile.clientId == 1) arr.push("Client Management");
      if (this.userProfile.clientId == 1) arr.push("CRON Manager");
      if (this.showRecommendationsTab) arr.push("Robin Manager");
      if (this.showMarqetaKYBTab) arr.push("KYB Manager");

      return arr;
    },
    routablePagesOnLoad() {
      var arr = [];
      console.log(this.userProfile);
      if (
        this.userProfile &&
        this.userProfile.Client &&
        this.userProfile.Client.displayActivityPage
      )
        arr.push({ label: "Activity", value: "activity" });
      if (
        this.userProfile &&
        this.userProfile.Client &&
        this.userProfile.Client.displayLibraryPage
      )
        arr.push({ label: "Library", value: "learning" });
      if (
        this.userProfile &&
        this.userProfile.Client &&
        this.userProfile.Client.displayWalletPage
      )
        arr.push({ label: "Wallet", value: "wallet" });
      if (
        this.userProfile &&
        this.userProfile.Client &&
        this.userProfile.Client.displayProgramPage
      )
        arr.push({ label: "Programs", value: "programs" });
      return arr;
    },
    showMigrationButton() {
      return (
        this.permissions.includes("auth0:create:migration") &&
        process.env.VUE_APP_ENVIRONMENT !== "dev"
      );
    },
    showRecommendationsTab() {
      return this.permissions.includes("recommendations:create:recommendation");
    },
    showCompanyTab() {
      return this.permissions.includes("users:update:client");
    },
    // showHierarchyTab() {
    //   return (
    //     this.permissions.includes("hierarchy:read:hierarchy") &&
    //     this.userProfile &&
    //     this.userProfile.clientId == 1
    //   );
    // },
    showPresetTab() {
      return (
        this.roles &&
        (this.roles.includes("40ClientAdminV2") ||
          this.roles.includes("30FinanceAdminV2"))
      );
    },
    showProcoreKickoff() {
      return this.permissions?.includes("procore:create:kickoff");
    },
    showMarqetaKYBTab() {
      return this.permissions.includes("marqeta:read:admin");
    },
    visibleClients() {
      var clients = [];
      if (this.clientFilters.clientCreation)
        clients = clients.concat(
          this.clients.filter(x => x.onboardingStatus == "CLIENT_CREATION")
        );
      if (this.clientFilters.emailVerified)
        clients = clients.concat(
          this.clients.filter(x => x.onboardingStatus == "EMAIL_VERIFIED")
        );
      if (this.clientFilters.blacklist)
        clients = clients.concat(
          this.clients.filter(x => x.onboardingStatus == "BLACKLIST")
        );
      if (this.clientFilters.agreements)
        clients = clients.concat(
          this.clients.filter(x => x.onboardingStatus == "AGREEMENTS")
        );
      if (this.clientFilters.kybPending)
        clients = clients.concat(
          this.clients.filter(x => x.onboardingStatus == "KYB_PENDING")
        );
      if (this.clientFilters.kybSuccess)
        clients = clients.concat(
          this.clients.filter(x => x.onboardingStatus == "KYB_SUCCESS")
        );
      if (this.clientFilters.complete)
        clients = clients.concat(
          this.clients.filter(
            x => x.onboardingStatus == "COMPLETE" || x.onboardingStatus == null
          )
        );
      return clients;
    },
    formattedSignupLink() {
      var env =
        process.env.VUE_APP_ENVIRONMENT == "dev"
          ? "dev"
          : process.env.VUE_APP_ENVIRONMENT == "test"
          ? "test"
          : "app";
      if (
        this.userProfile &&
        this.userProfile.Client &&
        this.userProfile.Client.onboardingToken
      ) {
        return `https://${env}.wewhistle.com/signup/${this.userProfile.Client.onboardingToken}`;
      }
      return null;
    },
    formattedSignupLinkEditor() {
      var env =
        process.env.VUE_APP_ENVIRONMENT == "dev"
          ? "dev"
          : process.env.VUE_APP_ENVIRONMENT == "test"
          ? "test"
          : "app";
      if (this.client && this.client.onboardingToken) {
        return `https://${env}.wewhistle.com/signup/${this.client.onboardingToken}`;
      }
      return null;
    },
    displayAnnualPaymentCap() {
      return !!(this.permissions || []).find(
        x => x == "clients:create:annualpaymentcap"
      );
    },
    Documents() {
      return [
        {
          id: "msa_v1",
          type: "PDF",
          name: "Master Services Agreement",
          description: "Contract for using the Whistle platform"
        },
        !this.permissions.includes("vue:read:microservice-api-docs")
          ? []
          : [
              {
                id: "form-service",
                type: "API",
                name: "Form Microservice"
              }
            ]
      ]
        .flat()
        .filter(Boolean);
    }
  },
  watch: {
    createClientWidget: function(newVal) {
      // Wipe the editing client when the drawer closes
      if (!newVal) this.adminClientToEdit = null;
    }
  }
};
</script>
<style>
.text {
  text-align: center;
  align-items: center;
}
.text-field {
  width: 50%;
  max-width: 50%;
}
.white-background {
  background-color: white;
}
</style>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.brand-background {
  background-color: var(--v-brand-base);
}
.border-radius {
  border-radius: 5px;
}

.box-border {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}

/* Removes gray header for document table */
.table >>> .v-data-table-header {
  background-color: white;
}
</style>
