<template>
  <div class="full-width">
    <v-card
      class="mb-3 d-flex flex-column align-start blue-gradient rounded-lg"
      :class="{ 'px-2 py-2': isMobile, 'px-4 py-4': !isMobile }"
      id="payment-admin-console"
      width="100%"
      flat
      v-if="adminView"
    >
      <div class="d-flex justify-space-between align-center full-width mb-3">
        <v-tooltip top :disabled="!display.pendingPayment || loading.budgets"
          ><template v-slot:activator="{ on, attrs }">
            <h3
              class="white--text work-break mt-1 text-left"
              v-on="on"
              v-bind="attrs"
            >
              Available to send
              <v-progress-circular
                v-if="loading.budgets"
                indeterminate
                color="white"
                width="2"
                size="25"
                class="ml-2"
              ></v-progress-circular>
              <span v-else class="word-break available to-send"
                ><strong
                  >{{ formatCurrency(funding.cumulativeBalance, true)
                  }}{{ display.pendingPayment ? "*" : "" }}
                </strong></span
              >
            </h3> </template
          ><span
            >Some pending payments may impact the funds available</span
          ></v-tooltip
        >
        <v-btn
          v-if="!loading.budgets && destinationBudget"
          outlined
          rounded
          dark
          @click="fundBudget(null, accountBalance, null, false, null, null)"
          >Add funds</v-btn
        >
      </div>
      <v-card
        class="d-flex justify-space-between align-center rounded"
        :class="{ 'px-2 py-2': isMobile, 'px-3 py-3': !isMobile }"
        width="100%"
        flat
        @click="loadPaymentConsole"
      >
        <div class="d-flex full-width align-center align-text-left">
          <v-icon class="mr-2 rounded wallet-icon dark-blue-background"
            >mdi-account-heart
          </v-icon>
          <h3><strong class="word-break ml-1">Send a reward</strong></h3>
        </div>
        <v-icon>mdi-chevron-right</v-icon>
      </v-card>
    </v-card>
    <v-card
      class="d-flex flex-column align-start green-gradient rounded-lg"
      :class="{ 'px-2 py-2': isMobile, 'px-4 py-4': !isMobile }"
      id="whistle-wallet"
      width="100%"
      flat
    >
      <div class="d-flex justify-space-between align-center full-width mb-3">
        <h3 class="white--text work-break mt-1 text-left">
          Available to spend
          <v-progress-circular
            v-if="loadingBalance"
            indeterminate
            color="white"
            width="2"
            size="25"
            class="ml-2"
          ></v-progress-circular>
          <span v-else class="word-break available to-spend"
            ><strong>{{ computedAvailableToSpend }} </strong></span
          >
        </h3>
        <!-- <v-btn
          v-if="display.virtualCard"
          @click.stop="toggleVirtualCardWidget"
          depressed
          :ripple="false"
          dark
          text
          >Close<v-icon class="mt-n1">mdi-close</v-icon></v-btn
        > -->
      </div>
      <transition-group name="list-slide" class="full-width" tag="div">
        <v-tooltip
          top
          :disabled="showVirtualCardInfo || showCardRequestForm || isTest"
          class="list-slide-item pb-0"
          :key="-3"
          ><template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs" class="">
              <!-- :class="display.virtualCard ? 'rounded-xl' : 'rounded'" -->
              <v-card
                :key="-1"
                class="list-slide-item mb-0 rounded"
                :disabled="
                  !showVirtualCardInfo && !showCardRequestForm && !isTest
                "
                width="100%"
                flat
                @click="toggleVirtualCardWidget"
                :ripple="false"
              >
                <div
                  class="full-width d-flex justify-space-between align-start"
                  :class="{ 'px-2 py-2': isMobile, 'px-3 py-3': !isMobile }"
                >
                  <div class="d-flex full-width align-center align-text-left">
                    <v-icon
                      class="mr-2 rounded wallet-icon dark-green-background"
                      >mdi-credit-card
                    </v-icon>
                    <h3 class="word-break ml-1">
                      {{
                        !showVirtualCardInfo && !isTest
                          ? "Get your card"
                          : "View your card"
                      }}
                    </h3>
                  </div>
                  <v-btn
                    v-if="display.virtualCard"
                    @click.stop="toggleVirtualCardWidget"
                    depressed
                    :ripple="false"
                    text
                    >Close<v-icon class="mt-n1">mdi-close</v-icon></v-btn
                  >
                  <v-img
                    v-else-if="cobrandedCardImage"
                    :src="cobrandedCardImage + '.small.png'"
                    width="30%"
                    max-width="100"
                    min-width="100"
                    class="image-border rounded-lg"
                  />
                  <v-img
                    v-else
                    src="@/assets/Wallet/whistle-card-default.png.small.png"
                    width="30%"
                    max-width="100"
                    min-width="100"
                    class="image-border rounded-lg"
                  />
                </div>
                <v-expand-transition>
                  <div class="full-width" v-if="display.virtualCard">
                    <div v-if="isTest">
                      <div class="py-2 px-1 d-flex flex-column align-center">
                        <v-img
                          src="@/assets/Wallet/whistle-card-default.png"
                          width="100%"
                          max-width="400"
                          max-height="200"
                          contain
                          class="mt-3 mb-1 list-slide-item"
                        />
                        <span class="test-card-text mt-1">
                          This card is for demo purposes only! <br /><a
                            @click.stop
                            href="https://whistlepayments.com/schedule-a-demo-payments/"
                            target="_blank"
                            >Request access</a
                          >
                          for a production account
                        </span>
                      </div>
                    </div>
                    <div class="list-slide-item" v-else>
                      <MarqetaDisplayCard
                        class="mx-2"
                        :cardOnly="true"
                        :elevation="false"
                        @get-cards="getCards"
                        @scrollToTop="scrollToTop"
                      />
                      <!-- This is here because for some reason, it existing caps the height of the card widget -->
                      <div class="hidden" style="max-height:1px;">111</div>
                    </div>
                  </div>
                </v-expand-transition>
              </v-card>
            </div>
          </template>
          <span>Getting your card is unavailable at this time.</span>
        </v-tooltip>
        <v-card
          v-if="showCardActivationForm"
          @click="showCardActivationWidget"
          :key="-4"
          class="mt-n4 d-flex justify-space-between align-center rounded list-slide-item"
          :class="{ 'px-2 py-2': isMobile, 'px-3 py-3': !isMobile }"
          width="100%"
          flat
        >
          <div class="d-flex full-width align-center align-text-left">
            <v-icon class="mr-2 rounded wallet-icon dark-green-background"
              >mdi-credit-card-check-outline
            </v-icon>
            <h4>
              <strong class="word-break ml-1"
                >Activate your physical card</strong
              >
            </h4>
          </div>
          <v-icon>mdi-chevron-right</v-icon>
        </v-card>
        <v-card
          :key="-2"
          class="mt-2 d-flex justify-space-between align-center rounded list-slide-item"
          :class="{
            'px-2 py-2': isMobile,
            'px-3 py-3': !isMobile,
            'mt-2': showCardActivationForm,
            'mt-n4': !showCardActivationForm
          }"
          width="100%"
          flat
        >
          <div class="d-flex full-width align-center align-text-left">
            <v-icon class="mr-2 rounded wallet-icon dark-green-background"
              >mdi-wallet
            </v-icon>
            <h4><strong class="word-break ml-1">My funds</strong></h4>
          </div>
          <h3 v-if="!loadingBalance">
            {{ computedBalance }}
          </h3>
          <div v-else>
            <v-progress-circular
              indeterminate
              color="brandCyan"
              width="2"
            ></v-progress-circular>
          </div>
        </v-card>
        <v-card
          class="mt-2 list-slide-item rounded"
          :class="{ 'px-2 py-2': isMobile, 'px-3 py-3': !isMobile }"
          width="100%"
          v-for="rule in sortedPaymentRules"
          flat
          :key="rule.paymentRuleId"
          @click="
            if (expandedRule == rule.paymentRuleId) {
              expandedRule = null;
            } else {
              expandedRule = rule.paymentRuleId;
            }
          "
          :ripple="false"
        >
          <div class="d-flex justify-space-between align-center">
            <div class="d-flex  align-center align-text-left">
              <v-icon
                class="mr-2 border-radius wallet-icon"
                :class="{
                  'error-background': rule.expiring,
                  'dark-green-background': !rule.expiring
                }"
                >{{ rule.icon }}</v-icon
              >
              <div
                class="d-flex flex-column justify-center text-left"
                style="min-width: 100px;"
              >
                <strong class="word-break mx-1">{{
                  rule.classification == "PER_DIEM" && !rule.description
                    ? "Per diem balance"
                    : rule.nonCash
                    ? rule.displayName
                    : rule.description
                }}</strong>
                <strong
                  v-if="rule.expiring"
                  class="ml-1"
                  :class="{
                    'error--text': rule.expiringRedText
                  }"
                  >Expires in {{ countdown(rule.endDate) }}</strong
                >
              </div>
            </div>
            <h3
              class="text-right word-break"
              style="max-width: 40%; min-width: 75px;"
            >
              {{
                formatCurrency(
                  rule.balance,
                  !rule.nonCash,
                  rule.unitSingular,
                  rule.unitPlural
                )
              }}
            </h3>
          </div>
          <v-expand-transition>
            <div
              v-if="expandedRule == rule.paymentRuleId"
              class="text-left"
              :key="`${rule.paymentRuleId}-expanded`"
            >
              <div class="text-left mt-2 ml-2">
                <p v-if="rule.nonCash && rule.description">
                  {{ rule.description }}
                </p>
                <p v-if="rule.nonCash">
                  Note: This balance is non-monetary and cannot be spent using
                  your Whistle Card.
                </p>
                <p v-if="rule.mcc && rule.mcc.length > 0">
                  Purchases limited to:
                  <span v-for="(mcc, i) in rule.mcc" :key="mcc.mccId">{{
                    mcc.displayName + (i == rule.mcc.length - 1 ? "" : ", ")
                  }}</span>
                </p>
                <p v-else-if="!rule.nonCash">No restrictions</p>
                <p class="mb-1" v-if="!rule.nonCash">
                  {{
                    !rule.expiring
                      ? "No expiration"
                      : "Expires on " + formatDate(rule.endDate)
                  }}
                </p>
              </div>
            </div>
          </v-expand-transition>
        </v-card>
        <!-- <v-btn
          v-if="hasOtherAwards && !magicLinkToken"
          :key="-10"
          text
          color="white"
          @click="loadProfile(3)"
          class="mt-2 mx-3"
          >View other awards</v-btn
        > -->
      </transition-group>
    </v-card>

    <!-- company store thing: locked to Whistle client -->
    <v-card
      class="d-flex flex-column align-start carhart rounded-lg mt-3"
      :class="{ 'px-2': isMobile, 'px-4 ': !isMobile }"
      id="whistle-wallet"
      width="100%"
      v-if="userProfile.clientId == 1"
      flat
    >
      <v-card
        :key="1"
        class=" d-flex justify-space-between align-center rounded list-slide-item my-4"
        :class="{
          'px-2 py-2': isMobile,
          'px-3 py-3': !isMobile
        }"
        width="100%"
        flat
        href="https://whistle.axomo.com/"
        target="_blank"
      >
        <div class="d-flex full-width align-center align-text-left">
          <v-icon class="mr-2 rounded wallet-icon carhart"
            >mdi-tshirt-crew
          </v-icon>
          <h4><strong class="word-break ml-1">Company Store</strong></h4>
        </div>
      </v-card>
    </v-card>

    <div
      class="d-flex flex-column align-start mt-5"
      :class="{ 'mx-7': !isMobile, 'mx-4': isMobile }"
    >
      <div
        class="d-flex mb-3 full-width justify-start align-center align-text-left cursor-pointer"
        @click="loadGivingWidget"
        v-if="givingClient"
      >
        <v-icon class="mr-2 pa-1">mdi-gift-outline</v-icon>
        <strong class="word-break ml-1"
          >Donate to
          {{
            userProfile && userProfile.Client && userProfile.Client.clientName
              ? userProfile.Client.clientName
              : " client"
          }}</strong
        >
      </div>
      <div
        class="d-flex mb-3 full-width justify-start align-center align-text-left cursor-pointer"
        v-if="showAppClipButton"
        @click="openAppClip"
      >
        <v-icon class="mr-2 pa-1 pl-2">mdi-cellphone-wireless</v-icon>
        <strong class="word-break ml-0">Add to Apple Pay</strong>
      </div>
      <div
        class="d-flex mb-3 full-width justify-start align-center align-text-left cursor-pointer"
        v-else-if="showAndroidButton"
        @click="openInstantApp"
      >
        <v-icon class="mr-2 pa-1 pl-2">mdi-cellphone-wireless</v-icon>
        <strong class="word-break ml-0">Add to Google Wallet</strong>
      </div>
      <v-card
        v-else-if="showCardReplacementForm && !isModal"
        @click="showMobileWalletInstructions"
        flat
        class="mb-3"
        width="100%"
      >
        <div class="d-flex full-width align-center align-text-left">
          <v-icon class="mr-2 pa-1 pl-2">mdi-cellphone-wireless</v-icon>
          <strong class="word-break ml-0">Add to mobile wallet</strong>
        </div>
      </v-card>
      <div
        class="d-flex full-width justify-start align-center align-text-left cursor-pointer"
        @click="togglePolicies"
      >
        <v-icon class="mr-2 pa-1">mdi-book-information-variant</v-icon>
        <strong class="word-break ml-1"
          >Cardholder policy and information</strong
        >
      </div>
      <v-expand-transition>
        <div v-if="display.policies" class="mt-0 px-10">
          <v-card
            flat
            class="mt-3"
            width="100%"
            @click="downloadFile('cardholder_agreement.pdf')"
          >
            <div class="d-flex full-width align-center align-text-left">
              <v-progress-circular
                v-if="loading.cha"
                indeterminate
                color="brandCyan"
                size="25"
                width="2"
                class="mr-2 ml-3 pa-1"
              />
              <v-icon v-else class="mr-2 pa-1"
                >mdi-file-document-outline</v-icon
              >
              <strong class="word-break ml-1">Cardholder Agreement</strong>
            </div>
          </v-card>
          <v-card
            flat
            class="mt-3 full-width"
            @click="downloadFile('issuing_bank_privacy_policy.pdf')"
          >
            <div class="d-flex full-width align-center align-text-left">
              <v-progress-circular
                v-if="loading.bankPrivacyPolicy"
                indeterminate
                color="brandCyan"
                size="25"
                width="2"
                class="mr-2 ml-3 pa-1"
              />
              <v-icon v-else class="mr-2 pa-1"
                >mdi-file-document-outline</v-icon
              >
              <strong class="word-break ml-1">Bank Privacy Policy</strong>
            </div>
          </v-card>

          <v-card flat class="mt-3 full-width" @click="downloadFile('faq.pdf')">
            <div class="d-flex full-width align-center align-text-left">
              <v-progress-circular
                v-if="loading.faq"
                indeterminate
                color="brandCyan"
                size="25"
                width="2"
                class="mr-2 ml-3 pa-1"
              />
              <v-icon v-else class="mr-2 pa-1">mdi-help-circle-outline</v-icon>
              <strong class="word-break ml-1">FAQ</strong>
            </div>
          </v-card>
        </div>
      </v-expand-transition>
      <div
        v-if="
          showCardRequestForm ||
            showCardReplacementForm ||
            showUserEditForm ||
            showPINUpdateForm ||
            showCardPINForm ||
            showAppClipButton ||
            showAndroidButton
        "
        class="d-flex full-width justify-start align-center align-text-left mt-3 cursor-pointer"
        @click="toggleAccountManagement"
      >
        <v-icon class="mr-2 pa-1">mdi-cog-outline</v-icon>
        <strong class="word-break ml-1">Account management</strong>
      </div>
      <v-expand-transition>
        <div v-if="display.management" class="mt-0 px-10">
          <v-card
            v-if="showCardRequestForm"
            :disabled="!marqeta.canRegister"
            @click="showCardRequestWidget"
            flat
            class="mt-3"
            width="100%"
          >
            <div class="d-flex full-width align-center align-text-left">
              <v-icon class="mr-2 pa-1">mdi-credit-card-outline</v-icon>
              <strong class="word-break ml-1">{{
                showVirtualCardInfo ? "Request physical card" : "Request a card"
              }}</strong>
            </div>
          </v-card>
          <!-- <v-card
            v-if="showCardActivationForm"
            :disabled="!marqeta.canRegister"
            @click="showCardActivationWidget"
            flat
            class="mt-3"
            width="100%"
          >
            <div class="d-flex full-width align-center align-text-left">
              <v-icon class="mr-2 pa-1">mdi-credit-card-check-outline</v-icon>
              <strong class="word-break ml-1">Activate your card</strong>
            </div>
          </v-card> -->
          <v-card
            v-if="showCardReplacementForm"
            :disabled="!marqeta.canRegister"
            @click="showCardReplacementWidget"
            flat
            class="mt-3"
            width="100%"
          >
            <div class="d-flex full-width align-center align-text-left">
              <v-icon class="mr-2 pa-1">mdi-credit-card-refresh-outline</v-icon>
              <strong class="word-break ml-1"
                >Cancel or replace an existing card</strong
              >
            </div>
          </v-card>
          <v-card
            v-if="showUserEditForm"
            :disabled="!marqeta.canRegister"
            @click="showUserEditWidget"
            flat
            class="mt-3"
            width="100%"
          >
            <div class="d-flex full-width align-center align-text-left">
              <v-icon class="mr-2 pa-1"
                >mdi-credit-card-settings-outline</v-icon
              >
              <strong class="word-break ml-1"
                >Update your card billing info</strong
              >
            </div>
          </v-card>
          <!-- <v-card
            v-if="showAppClipButton"
            flat
            @click="openAppClip"
            class="mt-3"
            width="100%"
          >
            <div class="d-flex full-width align-center align-text-left">
              <v-icon class="mr-2 pa-1">mdi-cellphone-wireless</v-icon>
              <strong class="word-break ml-1">Manage Apple Pay</strong>
            </div>
          </v-card> -->
          <!-- <v-card
            v-else-if="showCardReplacementForm && !isModal"
            @click="showMobileWalletInstructions"
            flat
            class="mt-3"
            width="100%"
          >
            <div class="d-flex full-width align-center align-text-left">
              <v-icon class="mr-2 pa-1">mdi-cellphone-wireless</v-icon>
              <strong class="word-break ml-1">Add to mobile wallet</strong>
            </div>
          </v-card> -->

          <v-card
            v-if="showPINUpdateForm || showCardPINForm"
            @click="showCardPINWidget"
            flat
            class="mt-3"
            width="100%"
          >
            <div class="d-flex full-width align-center align-text-left">
              <v-icon class="mr-2 pa-1">mdi-lock-outline</v-icon>
              <strong class="word-break ml-1">{{
                showPINUpdateForm ? "Update your PIN" : "Set your PIN"
              }}</strong>
            </div>
          </v-card>
        </div>
      </v-expand-transition>
      <div
        v-if="isModal"
        class="d-flex full-width justify-start align-center align-text-left mt-3 cursor-pointer"
        @click="toggleRecentTransactions"
      >
        <v-icon class="mr-2 pa-1">mdi-format-list-bulleted</v-icon>
        <strong class="word-break ml-1">Recent transactions</strong>
      </div>
      <v-expand-transition>
        <div v-if="display.transactions" class="mt-1 full-width text-left">
          <TransactionLog
            :key="1"
            :isModal="isModal"
            @load-wallet="loadWallet"
          />
        </div>
      </v-expand-transition>
      <div
        v-if="magicLinkToken"
        class="d-flex full-width justify-start align-center align-text-left mt-3 cursor-pointer"
        @click="logout"
      >
        <v-icon class="mr-2 pa-1">mdi-logout</v-icon>
        <strong class="word-break ml-1">Logout</strong>
      </div>
      <p class="ml-2 mt-3 darkGrey--text text-left">
        If you need additional help, contact us at
        <a href="mailto:help@wewhistle.com" class="text-decoration-none"
          >help@wewhistle.com</a
        >
      </p>
    </div>

    <div class="white-background">
      <v-dialog
        fullscreen
        v-model="dialogCardRequest"
        transition="dialog-bottom-transition"
        class="z-index-front"
        width="50%"
      >
        <v-card rounded="0">
          <MarqetaCardRequest
            v-if="dialogCardRequest"
            :cards="marqeta.cards"
            :alreadyRegistered="marqeta.alreadyRegistered"
            @reset="closeCardForm"
            @get-cards="getCards"
          ></MarqetaCardRequest>
        </v-card>
      </v-dialog>
    </div>
    <div class="white-background">
      <v-dialog
        fullscreen
        v-model="dialogCardReplacement"
        transition="dialog-bottom-transition"
        class="z-index-front"
      >
        <v-card rounded="0">
          <MarqetaCardReplacement
            v-if="dialogCardReplacement"
            :cards="marqeta.cards"
            :alreadyRegistered="marqeta.alreadyRegistered"
            @reset="closeCardForm"
            @get-cards="getCards"
          ></MarqetaCardReplacement>
        </v-card>
      </v-dialog>
    </div>
    <v-navigation-drawer
      v-model="display.funding"
      temporary
      fixed
      right
      width="500"
      disable-route-watcher
    >
      <StripePaymentMethodsManager
        v-if="display.funding"
        @reset="display.funding = false"
        @get-budgets="getBudgets"
        @get-funding-limit="getBudgetFundingStatuses"
        :destinationBudget="destinationBudget"
        :sourceBudget="null"
        :budgets="funding.budgets"
        :clientId="this.userProfile.clientId"
        :preloadMenu="null"
        :requestFunds="budgetFundingRequest"
        :fundingLimit="clientFundingStatus"
        source="BUDGETS"
        :displayBudgetTransfer="false"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
// import MarqetaService from "@/services/MarqetaService";
import UserService from "@/services/UserService";
import BudgetService from "@/services/BudgetService";
import WalletService from "@/services/WalletService";
// import { states, countries, countryCodes } from "@/shared_data/data.js";
// import MarqetaBusinessCreation from "@/components/MarqetaBusinessCreation.vue";
import MarqetaDisplayCard from "@/components/wallet/MarqetaDisplayCard.vue";
import MarqetaCardRequest from "@/components/wallet/MarqetaCardRequest.vue";
import MarqetaCardReplacement from "@/components/wallet/MarqetaCardReplacement.vue";
import TransactionLog from "@/components/wallet/TransactionLog.vue";
import StripePaymentMethodsManager from "@/components/stripe/StripePaymentMethodsManager.vue";

import moment from "moment";
import currency from "currency.js";
import { mapState } from "vuex";

export default {
  name: "MarqetaWidgetManagerV2",
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    isolate: String,
    showVirtualCard: Boolean,
    previousRoute: String
  },
  components: {
    // MarqetaBusinessCreation,
    MarqetaCardRequest,
    MarqetaCardReplacement,
    MarqetaDisplayCard,
    TransactionLog,
    StripePaymentMethodsManager
  },
  data() {
    return {
      dialogCardRequest: false,
      dialogCardReplacement: false,
      showManageCard: false,
      showPolicyInfo: false,
      expandedRule: null,
      loading: {
        cha: false,
        bankPrivacyPolicy: false,
        faq: false,
        balance: false,
        fundingStatus: false,
        budgets: false
      },
      display: {
        virtualCard: false,
        policies: false,
        management: false,
        transactions: false,
        funding: false,
        pendingPayment: false
      },
      search: {
        authorizations: null,
        transactions: null
      },
      funding: {
        budgets: [],
        cumulativeBalance: 0,
        sourceBudget: null,
        destinationBudget: null,
        preloadPaymentMenu: null,
        fundingArray: []
      },
      transactionColumns: [
        { text: "Date", value: "formattedDate", width: "35%", sortable: false },
        {
          text: "Description",
          value: "merchantName",
          width: "50%",
          sortable: false,
          align: "start"
        },
        {
          text: "Amount",
          value: "amount",
          width: "15%",
          align: "end",
          sortable: false
        }
      ],
      pendingPaymentTimeout: null
    };
  },
  created() {
    this.dialogCardRequest = false;
    this.refreshWallet();
    this.getBudgets();
    // If we're coming from the payment wizard, we check for pending payments to show the asterisk next to budget balance
    if (this.previousRoute && this.previousRoute == "paymentwizard") {
      this.getPendingPayments(1);
    }
  },
  beforeDestroy() {},
  destroyed() {
    clearInterval(this.pendingPaymentTimeout);
  },
  methods: {
    formatCurrency(
      amount,
      cash = true,
      unitSingular = "unit",
      unitPlural = "units"
    ) {
      return (
        currency(amount, { symbol: cash ? "$" : "" }).format() +
        (cash ? "" : ` ${amount == 1 ? unitSingular : unitPlural}`)
      );
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
    getCards(source) {
      console.log("Calling getCards", source);
      this.refreshWallet();
    },
    refreshWallet() {
      this.$store.dispatch("refreshWallet");
      window.scrollTo(0, 0);
    },
    closeCardForm(loadVirtualCard) {
      console.log("Closing card form ", loadVirtualCard);
      this.dialogCardRequest = false;
      this.dialogCardReplacement = false;
      if (loadVirtualCard) {
        if (this.displayWalletMenu) {
          // The wallet menu is already open so we load the new wallet screen;
          this.walletScreen = "display-virtual";
          this.scrollToTopOfWalletDrawer();
        } else {
          this.$store.dispatch("setDisplayWalletMenu", true);
          // We have to set the source page so we know when they click back, it closes the drawer
          this.$store.dispatch("setSourceWalletPage", "wallet");
          this.walletScreen = "display-virtual";
          this.scrollToTopOfWalletDrawer();
        }
      }
    },
    async downloadFile(fileName) {
      if (fileName.includes("faq")) this.loading.faq = true;
      else if (fileName.includes("cardholder")) this.loading.cha = true;
      else if (fileName.includes("privacy"))
        this.loading.bankPrivacyPolicy = true;
      await UserService.downloadCardProductFile(fileName, this.magicLinkToken);
      this.loading.faq = false;
      this.loading.cha = false;
      this.loading.bankPrivacyPolicy = false;
    },
    showCardRequestWidget() {
      this.dialogCardRequest = true;
    },
    showCardReplacementWidget() {
      this.display.virtualCard = false;
      this.dialogCardReplacement = true;
    },

    showVirtualCardWidget() {
      // If we're on the wallet screen and the wallet drawer isn't open, then we open it
      if (this.onWalletPage && !this.displayWalletMenu) {
        this.$store.dispatch("setDisplayWalletMenu", true);
        // We have to set the source page so we know when they click back, it closes the drawer
        this.$store.dispatch("setSourceWalletPage", "wallet");
      }
      this.scrollToTopOfWalletDrawer();
      this.walletScreen = "display-virtual";
    },
    showCardActivationWidget() {
      // If we're on the wallet screen and the wallet drawer isn't open, then we open it
      if (this.onWalletPage && !this.displayWalletMenu) {
        this.$store.dispatch("setDisplayWalletMenu", true);
        // We have to set the source page so we know when they click back, it closes the drawer
        this.$store.dispatch("setSourceWalletPage", "wallet");
      }
      this.scrollToTopOfWalletDrawer();
      this.walletScreen = "card-activation";
    },
    showCardPINWidget() {
      // If we're on the wallet screen and the wallet drawer isn't open, then we open it
      if (this.onWalletPage && !this.displayWalletMenu) {
        this.$store.dispatch("setDisplayWalletMenu", true);
        // We have to set the source page so we know when they click back, it closes the drawer
        this.$store.dispatch("setSourceWalletPage", "wallet");
      }
      this.scrollToTopOfWalletDrawer();
      this.walletScreen = "card-pin";
    },
    showMobileWalletInstructions() {
      if (this.showVirtualCardInfo) {
        // OPen the wallet and scroll to the top
        this.toggleVirtualCardWidget(true);
        this.scrollToTopOfWalletDrawer();
      } else if (this.isTest) this.scrollToTop();
      // If we're on the wallet screen and the wallet drawer isn't open, then we open it
      if (this.onWalletPage && !this.displayWalletMenu) {
        this.$store.dispatch("setDisplayWalletMenu", true);
        // We have to set the source page so we know when they click back, it closes the drawer
        this.$store.dispatch("setSourceWalletPage", "wallet");
      }
      //this.scrollToTopOfWalletDrawer();
      //this.walletScreen = "display-mobile-wallet-instruction";
      this.$store.dispatch("setWalletMenuScreen", "display-add-to-mobile");
    },
    openAppClip() {
      // We need to change the current route and add the token to the url
      // let query = Object.assign({}, this.$route.query);
      // query.token = this.magicLinkToken || this.token;
      // if (this.magicLinkToken) query["magic-link"] = "1";
      // this.$router.replace({ query });
      //   // const url = process.env.VUE_APP_APP_CLIP_URL;

      // let url = `https://app.wewhistle.com/wallet/app-clip?token=${this
      //   .magicLinkToken || this.token}`;
      // let url = `http://localhost:8080/wallet/app-clip?token=${this
      // .magicLinkToken || this.token}`;
      // if (this.magicLinkToken) url += `&magic-link=1`;

      // setTimeout(() => {
      window.open(this.appClipUrl, "_blank");
      // console.log("Changing router window");
      // this.$router.push({
      //   name: "walletappclip",
      //   query: {
      //     token: this.magicLinkToken || this.token,
      //     "magic-link": this.magicLinkToken ? 1 : 0
      //   }
      // });
      // }, 2000);
    },
    openInstantApp() {
      window.open(this.instantAppUrl, "_blank");
    },
    showUserEditWidget() {
      this.scrollToTopOfWalletDrawer();
      this.walletScreen = "update-user";
      // If we're on the wallet screen and the wallet drawer isn't open, then we open it
      if (this.onWalletPage && !this.displayWalletMenu) {
        this.$store.dispatch("setDisplayWalletMenu", true);
        // We have to set the source page so we know when they click back, it closes the drawer
        this.$store.dispatch("setSourceWalletPage", "wallet");
      }
    },
    showPerDiemReportWidget() {
      this.scrollToTopOfWalletDrawer();
      this.walletScreen = "per-diem-report";
      // If we're on the wallet screen and the wallet drawer isn't open, then we open it
      if (this.onWalletPage && !this.displayWalletMenu) {
        this.$store.dispatch("setDisplayWalletMenu", true);
        // We have to set the source page so we know when they click back, it closes the drawer
        this.$store.dispatch("setSourceWalletPage", "wallet");
      }
    },
    scrollToTopOfWalletDrawer() {
      this.$emit("scroll-top");
      // If we're on the wallet screen and the wallet drawer isn't open, then we open it
      if (this.onWalletPage && !this.displayWalletMenu) {
        this.$store.dispatch("setDisplayWalletMenu", true);
        // We have to set the source page so we know when they click back, it closes the drawer
        this.$store.dispatch("setSourceWalletPage", "wallet");
      }
    },
    loadWallet(tab) {
      // We have to check if we're already on the router. If so, we just close the profile
      if (this.onWalletPage) {
        // We close the wallet
        //this.closeWallet();
        this.$emit("close-wallet");
        console.log("emitting close-wallet");
      } else {
        this.$router.push({
          name: "wallet",
          params: { initialPageIndexToLoad: tab }
        });
      }
    },
    loadGivingWidget() {
      // If we're on the wallet page, we emit and load the Wallet component for this
      // If we're in the wallet nav drawer, we show that special screen
      this.scrollToTopOfWalletDrawer();
      this.walletScreen = "donate";
      // If we're on the wallet screen and the wallet drawer isn't open, then we open it
      if (this.onWalletPage && !this.displayWalletMenu) {
        this.$store.dispatch("setDisplayWalletMenu", true);
        // We have to set the source page so we know when they click back, it closes the drawer
        this.$store.dispatch("setSourceWalletPage", "wallet");
      }
      this.scrollToTopOfWalletDrawer();
    },
    scrollToTop() {
      document.getElementsByClassName(
        "v-dialog v-dialog--active"
      )[0].scrollTop = 0;
    },
    loadPaymentConsole() {
      this.$router.push({
        name: "paymentwizard",
        query: { previousRoute: this.$route.name },
        params: {
          //   preload: "wizard",
          previousRoute: this.$route.name
          //   oneTimePayment: true
        }
      });
    },
    loadProfile(tab) {
      if (this.$route.name != "profile")
        this.$router.push({
          name: "profile",
          params: { initialPageIndexToLoad: tab }
        });
    },
    countdownMillis(date) {
      var now = moment();
      var localTime = moment(date).utc();
      let dur = moment.duration(localTime - now);
      return dur;
    },
    countdown(date) {
      // console.log(date);
      var now = moment();
      var localTime = moment(date).utc();
      let dur = moment.duration(localTime - now);
      if (dur.years() != 0) {
        return dur.years() + (dur.years() == 1 ? " year" : " years");
      }
      if (dur.months() != 0) {
        return dur.months() + (dur.months() == 1 ? " month" : " months");
      }
      if (dur.days() != 0) {
        return dur.days() + (dur.days() == 1 ? " day" : " days");
      }
      // console.log(dur.hours());
      if (dur.hours() != 0) {
        return dur.hours() + (dur.hours() == 1 ? " hour" : " hours");
      }
      if (dur.minutes() != 0) {
        return dur.minutes() + (dur.minutes() == 1 ? " minute" : " minutes");
      }
    },
    formatDate(date) {
      var localTime = moment(date)
        .utc()
        .local()
        .format("MMM Do, YYYY, h:mm a");
      return localTime;
    },

    // V2
    toggleVirtualCardWidget(dontHideCard) {
      if (
        dontHideCard === true &&
        this.showVirtualCardInfo &&
        this.display.virtualCard
      )
        return console.log(
          "Returning early because we don't need to hide the virtual card"
        );
      if (!this.showVirtualCardInfo && !this.isTest)
        this.showCardRequestWidget();
      else this.display.virtualCard = !this.display.virtualCard;
    },
    togglePolicies() {
      this.display.policies = !this.display.policies;
    },
    toggleAccountManagement() {
      this.display.management = !this.display.management;
    },
    toggleRecentTransactions() {
      this.display.transactions = !this.display.transactions;
    },
    async getBudgetFundingStatuses() {
      // this is used to get the client's balance on how much they have funded if they're using Whistle's KYB status
      // So we can cap them at 1,000
      try {
        this.loading.fundingStatus = true;
        const response = await BudgetService.getBudgetFundingStatuses(
          this.magicLinkToken
        );
        console.log("KYB funding response ", response);
        this.loading.fundingStatus = false;

        this.funding.fundingArray = response.map(x => {
          if (x.cooldownExpiration)
            x.cooldownExpirationLocal = moment(x.cooldownExpiration)
              .local()
              .format("MMM Do h:mm a");
          return x;
        });
      } catch (e) {
        console.log("Error getting funding statuses ", e);
      }
    },
    async getBudgets() {
      // this is used to get the client's balance on how much they have funded if they're using Whistle's KYB status
      // So we can cap them at 1,000
      if (!this.adminView)
        return console.log("No admin privileges. Not getting budgets");
      try {
        this.loading.budgets = true;
        const response = await BudgetService.getBudgetsAdminView(
          { clientId: this.userProfile.clientId, lite: 1, awardId: 1, sum: 1 },
          this.magicLinkToken
        );
        console.log("Budget response ", response);
        this.loading.budgets = false;

        this.funding.budgets = response.result.rows;
        this.funding.cumulativeBalance = response.result.sum;
      } catch (e) {
        console.log("Error getting budgets ", e);
      }

      this.getBudgetFundingStatuses();
    },
    async getPendingPayments(attempt = 1) {
      try {
        // this.loading.recent = true;
        console.log("Getting pending payments for attempt ", attempt);
        const filter = `clientId == ${
          this.userProfile.clientId
        } && status = 'Draft' && createDate >= '${moment().subtract(
          15,
          "minute"
        )}'`;
        console.log("Parent payment rule filter ", filter);
        let results = await WalletService.getParentPaymentRulesV2(
          {
            filter,
            limit: 1,
            sort: "createDate DESC",
            extract: "parentPaymentRuleId,clientId"
          },
          this.magicLinkToken
        );
        console.log("Got recent parent payment rules ", results.result.rows);
        if (results.result.count) this.display.pendingPayment = true;
        else this.display.pendingPayment = false;

        // We're on a repeat attempt and didn't find any, so we can refresh
        if (attempt > 1 && !results.result.count) this.getBudgets();
        else if (attempt < 3 && results.result.count) {
          this.pendingPaymentTimeout = setTimeout(
            () => this.getPendingPayments(attempt + 1),
            10000
          );
        }
      } catch (err) {
        console.log("Error with parent payment rules ", err);
      }
      //  finally {
      //   this.loading.recent = false;
      // }
    },
    fundBudget(
      source,
      destination,
      preloadMenu,
      requestFunds = false,
      amount = null,
      budgetActivityId = null
    ) {
      console.log(source);
      console.log(destination);
      console.log(preloadMenu);
      console.log(requestFunds);
      console.log(amount);
      this.display.funding = true;
      this.sourceBudgetForFunding = source;
      this.destinationBudgetForFunding = destination;
      this.preloadPaymentMenu = preloadMenu;
      this.budgetFundingRequest = requestFunds;
      this.preloadFundingAmount = amount;
      this.preloadBudgetActivityId = budgetActivityId;
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "permissions",
      "roles",
      "walletMenuScreen",
      "displayWalletMenu",
      "marqeta",
      "balances",
      "cashBalance",
      "loadingBalance",
      "magicLinkToken",
      "token"
    ]),
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    isTest() {
      return process.env.VUE_APP_ENVIRONMENT == "test";
    },
    onWalletPage() {
      return this.$route.name == "wallet" || this.$route.name == "giving";
    },
    walletScreen: {
      get: function() {
        return this.walletMenuScreen;
      },
      set: function(newValue) {
        this.$store.dispatch("setWalletMenuScreen", newValue);
      }
    },
    // hasOtherAwards() {
    //   return (
    //     this.balances.filter(x => parseFloat(x.balance).toFixed(0) > 0).length >
    //     0
    //   );
    // },
    sortedPaymentRules() {
      return this.userProfile.PaymentRules.filter(
        x => x.displayBalanceInWallet && x.expiring
      )
        .sort(
          (a, b) =>
            this.countdownMillis(a.endDate) - this.countdownMillis(b.endDate)
        )
        .concat(
          this.userProfile.PaymentRules.filter(
            x => x.displayBalanceInWallet && !x.expiring
          ).sort(
            (a, b) =>
              this.countdownMillis(b.startDate) -
              this.countdownMillis(a.startDate)
          )
        )
        .concat(this.nonCashBalances);
    },
    nonCashBalances() {
      if (!this.balances || !Array.isArray(this.balances)) return [];
      return this.balances
        .filter(x => x.balance > 0)
        .map(x => {
          console.log(x);
          x.icon = "mdi-gift";
          x.paymentRuleId = `noncash-${x.awardId}`;
          x.nonCash = true;
          return x;
        });
    },
    showCardRequestForm() {
      // if (this.userProfile && this.userProfile.businessCountry != "US") {
      //   return false;
      // }
      if (
        !this.marqeta
          .canRegister /*||
        (this.isolate != "cardRequestForm" && this.isolate != null)*/
      ) {
        return false;
      }
      // console.log(this.marqeta);
      if (this.marqeta.error_code) {
        // We had an error, so we check the error code and render things differently
        if (this.marqeta.error_code == 1190004) {
          // The user isn't setup, so we allow any card requests and hide card activation
          return true;
        } else if (
          this.marqeta.error_code == 1190005 ||
          this.marqeta.error_code == 1190019 ||
          this.marqeta.error_code == 1190020
        ) {
          // We hide all because the business is not setup
          return false;
        }
      } else {
        // If the user doesn't have a card, then we show that request form
        if (!this.marqeta.cards.find(x => x.type == "virtual")) {
          return true;
        } else if (
          !this.marqeta.cards.find(x => x.type == "physical") &&
          this.cashBalance >= this.userProfile.Client.physicalCardFee
        ) {
          return true;
        } else {
          return false;
        }
      }

      return false;
    },
    showCardReplacementForm() {
      // if (this.userProfile.clientId == 1) {
      //   return true;
      // }
      if (
        !this.marqeta
          .canRegister /*||
        (this.isolate != "cardReplacementForm" && this.isolate != null)*/
      ) {
        return false;
      }
      if (this.marqeta.error_code) {
        return false;
      } else {
        // If the user has a card, then we show the replacement form
        if (
          this.marqeta.cards.find(x => x.type == "physical") ||
          this.marqeta.cards.find(x => x.type == "virtual")
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    showCardActivationForm() {
      // if (this.userProfile.clientId == 1) {
      //   return true;
      // }
      if (
        !this.marqeta
          .canRegister /*||
        (this.isolate != "cardActivationForm" && this.isolate != null)*/
      ) {
        return false;
      }
      if (this.marqeta.error_code) {
        // We had an error, so we check the error code and render things differently
        return false;
      } else {
        // If the user has a physical card but unactivated, then we show the activation form
        if (
          this.marqeta.cards.find(
            x => x.type == "physical" && x.state == "UNACTIVATED"
          )
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    showCardPINForm() {
      // if (this.userProfile.clientId == 1) {
      //   return true;
      // }
      if (
        !this.marqeta
          .canRegister /*||
        (this.isolate != "cardPINForm" && this.isolate != null)*/
      ) {
        return false;
      }
      if (this.marqeta.error_code) {
        // We had an error, so we check the error code and render things differently
        return false;
      } else {
        // If the user has a physical card but unactivated, then we show the activation form
        if (
          this.marqeta.cards.find(
            x => x.pin_is_set === false && x.state == "ACTIVE"
          )
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    showPINUpdateForm() {
      // if (this.userProfile.clientId == 1) {
      //   return true;
      // }
      if (
        !this.marqeta
          .canRegister /*||
        (this.isolate != "cardPINUpdateForm" && this.isolate != null)*/
      ) {
        return false;
      }
      if (this.marqeta.error_code) {
        // We had an error, so we check the error code and render things differently
        return false;
      } else {
        // If the user has a physical card but unactivated, then we show the activation form
        if (
          this.marqeta.cards.find(
            x => x.pin_is_set === true && x.state == "ACTIVE"
          )
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    showBusinessCreationForm() {
      if (this.userProfile.clientId == 1) {
        return true;
      }
      if (this.marqeta.error_code) {
        // We had an error, so we check the error code and render things differently
        if (this.marqeta.error_code == 1190004) {
          // The user isn't setup, so we allow any card requests and hide card activation
          return false;
        } else if (this.marqeta.error_code == 1190005) {
          // We hide all because the business is not setup
          if (this.permissions.includes("marqeta:create:business")) {
            return true;
          }
        }
      } else {
        return false;
      }
      return false;
    },
    showVirtualCardInfo() {
      // if (this.userProfile && this.userProfile.businessCountry != "US") {
      //   return false;
      // }
      // if (!this.marqeta.canRegister) {
      //   return false;
      // }
      if (
        this.marqeta
          .error_code /*||
        (this.isolate != "virtualCardInfo" && this.isolate != null)*/
      ) {
        return false;
      } else {
        // If the user doesn't have a virtual card, then we show that request form
        if (!this.marqeta.cards.find(x => x.type == "virtual")) {
          return false;
        } else {
          return true;
        }
      }
    },
    showUserEditForm() {
      // if (this.userProfile.clientId == 1) {
      //   return true;
      // }
      //  We base it on alreadyRegisterd
      if (!this.marqeta.canRegister) {
        return false;
      }
      return this.marqeta.alreadyRegistered;
    },
    showPerDiemReport() {
      // We base it on if they haven't signed off yet.
      return (
        this.userProfile.PaymentRules.filter(x => x.displayInPerDiemReports)
          .length > 0
      );
    },
    showCardTokenization() {
      if (this.userProfile.clientId == 1) {
        return true;
      }
      if (this.marqeta.error_code) {
        // We had an error, so we check the error code and render things differently
        return false;
      } else {
        // If the user doesn't have a physical card, then we show that request form
        if (
          this.marqeta.cards.find(x => x.type == "physical") ||
          this.marqeta.cards.find(x => x.type == "virtual")
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    givingClient() {
      // Indicates if the client is set up to support Giving cards
      return (
        this.userProfile &&
        this.userProfile.Client &&
        this.userProfile.Client.internalCustom1 == "GIVING"
      );
    },
    computedBalance: {
      cache: false,
      get: function() {
        // var bal = currency(this.cashBalance)
        // if (
        //   this.userProfile &&
        //   this.userProfile.businessEmail &&
        //   !this.userProfile.businessEmail.endsWith("@wewhistle.com") &&
        //   this.userProfile.clientId != 166 &&
        //   bal != 0
        // ) {
        //   var provisional = this.userProfile.PaymentRules.filter(
        //     (x) => x.displayBalanceInWallet
        //   );
        //   provisional.forEach((rule) => {
        //     bal = bal - rule.balance;
        //   });
        // }
        //Subtraction can cause lots of floating point problems of 0.99999999 and toFixed rounds up, so I hack a solution here
        return this.formatCurrency(this.cashBalance || 0, true);
      }
    },

    computedAvailableToSpend: {
      cache: false,
      get: function() {
        var bigTotal = currency(0);

        var paymentRules = this.userProfile.PaymentRules.filter(
          x => x.displayBalanceInWallet
        );

        bigTotal = paymentRules.reduce((accumulator, currentValue) => {
          return accumulator.add(currentValue.balance);
        }, currency(0));

        bigTotal = bigTotal.add(this.cashBalance || 0);

        return this.formatCurrency(bigTotal, true);
      }
    },
    cobrandedCardImage() {
      if (
        this.userProfile &&
        this.userProfile.Client &&
        this.userProfile.Client.internalCustom2 &&
        this.userProfile.Client.internalCustom2.startsWith("https")
      )
        return this.userProfile.Client.internalCustom2;
      return null;
    },
    clientFundingStatus() {
      if (this.userProfile.clientId && !this.loading.fundingStatus) {
        var status = this.funding.fundingArray.find(
          x => x.clientId == this.userProfile.clientId
        );
        return status;
      }
      return null;
    },
    accountBalance() {
      if (this.userProfile && this.userProfile.clientId) {
        return this.funding.budgets.find(
          x => x.clientId == this.userProfile.clientId && x.masterBudget
        );
      }
      return null;
    },
    destinationBudget() {
      // Return a budget where the user has owner privileges
      if (
        this.accountBalance &&
        this.accountBalance.permissions &&
        this.accountBalance.permissions.owner
      )
        return this.accountBalance;
      const budgetWithBalance = this.funding.budgets.find(
        x => x.permissions && x.permissions.owner && x.budgetBalance
      );
      if (budgetWithBalance) return budgetWithBalance;
      return this.funding.budgets.find(
        x => x.permissions && x.permissions.owner
      );
    },
    // cumulativeBudgetBalance() {
    //   if (this.loading.budgets) return 0;
    //   let sum = this.funding.budgets
    //     .filter(x => x.budgetBalance)
    //     .reduce(
    //       (prev, curr) => currency(prev).add(curr.budgetBalance),
    //       currency(0)
    //     ).value;
    //   return sum;
    // },
    adminView() {
      // Permissions for if they can send payments
      let paymentAdminRoles = [
        "20PaymentsAdminV2",
        "20Payments+GeneralAdminV2",
        "30FinanceAdminV2",
        "40ClientAdminV2"
        // "98WhistleFinanceAdminV2"
      ];
      return (
        this.permissions.includes("wallets:create:cashpayment") ||
        this.roles.find(x => paymentAdminRoles.includes(x))
      );
    },
    showAppClipButton() {
      var ua = window.navigator.userAgent;
      var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
      var webkit = !!ua.match(/WebKit/i);
      var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
      // console.log("showAppClipButton ", { ua, iOS, webkit, iOSSafari });
      if (
        this.userProfile &&
        (this.userProfile.businessEmail || "").includes("@wewhistle.com")
      )
        return !!(
          iOS &&
          // webkit &&
          // iOSSafari &&
          this.showVirtualCardInfo &&
          process.env.VUE_APP_APP_CLIP_URL
        );

      return !!(
        iOSSafari &&
        this.showVirtualCardInfo &&
        process.env.VUE_APP_APP_CLIP_URL
      );
    },
    developer() {
      const email = (this.userProfile && this.userProfile.businessEmail) || "";
      return [
        "daniel@wewhistle.com",
        "ben@wewhistle.com",
        "phil@wewhistle.com",
        "daniel+523+l@wewhistle.com"
      ].includes(email);
    },
    userAgent() {
      return window.navigator.userAgent;
    },
    showAndroidButton() {
      var ua = window.navigator.userAgent;
      console.log(ua.indexOf("android"));
      const isAndroid =
        ua.indexOf("android") > -1 || ua.indexOf("Android") > -1;
      // var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
      // var webkit = !!ua.match(/WebKit/i);
      // var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
      const email = (this.userProfile && this.userProfile.businessEmail) || "";
      // console.log("showAndroidButton ", { ua, isAndroid, email });
      if (email.includes("@wewhistle.com") && !email.includes("drew"))
        return !!(
          isAndroid &&
          this.showVirtualCardInfo &&
          process.env.VUE_APP_INSTANT_APP_URL
        );
      else if (
        [
          "daniel@wewhistle.com",
          "ben@wewhistle.com",
          "phil@wewhistle.com"
        ].includes(email)
      )
        return true;
      return false;
      // return !!(
      //   isAndroid &&
      //   // iOSSafari &&
      //   this.showVirtualCardInfo &&
      //   process.env.VUE_APP_INSTANT_APP_URL
      // );
    },
    appClipUrl() {
      // let url = `https://app.wewhistle.com/wallet/app-clip?token=${this
      // .magicLinkToken || this.token}`;
      // let url = `http://localhost:8080/wallet/app-clip?token=${this
      // .magicLinkToken || this.token}`;
      let url = `${process.env.VUE_APP_APP_CLIP_URL}&token=${this
        .magicLinkToken || this.token}`;
      if (this.magicLinkToken) url += `&magic-link=1`;
      return url;
    },
    instantAppUrl() {
      let url = `${process.env.VUE_APP_INSTANT_APP_URL}?token=${this
        .magicLinkToken || this.token}`;
      if (this.magicLinkToken) url += `&magic-link=1`;
      return url;
    }
  },
  watch: {
    displayWalletMenu(val) {
      if (!val && !this.isModal) {
        // If the drawer closed while we're on the wallet, refresh
        // if (this.$refs["marqeta-widget"]) {
        //   this.$refs["marqeta-widget"].getCards(
        //     "walletV3 displayWalletMenu watcher"
        //   );
        // }
        console.log(
          "Triggering balance and budget refresh once the wallet drawer closes"
        );
        this.refreshWallet();
        this.getBudgets();
        // If the drawer opens AND we're the wallet page watching AND the virtual card is already visible, then we hide it
      } else if (
        val &&
        !this.isModal &&
        this.display.virtualCard &&
        // The only time we want to keep the virtual card open was for tokenization
        this.walletMenuScreen != "display-add-to-mobile"
      ) {
        this.toggleVirtualCardWidget();
      }
    }
  }
};
</script>

<style scoped>
.blue-gradient {
  background: #00b0f3;
  background: linear-gradient(0deg, #09acec 0%, #00b0f3);
}
.green-gradient {
  background: rgb(162, 215, 109);
  background: linear-gradient(0deg, #379f0a 0%, #8fd75a);
}
.gray-gradient {
  background: #686868;
  background: linear-gradient(0deg, #454545 0%, #686868);
}
.carhart {
  background: #d9a762;
}
.dark-green-background {
  background: #379f0a;
}
.dark-blue-background {
  background: #09acec;
}

.image-border {
  border: solid 0.75px;
  border-color: lightgray;
  border-radius: 0;
}

.wallet-icon {
  color: #fff;
  padding: 6px;
  border-radius: 4px;
  font-size: 1.3em;
}

.error-background {
  background: var(--v-error-base);
}
.white-background {
  background: white;
  background-color: white;
  transform: scale(2);
}

.available {
  font-size: 20px;
}

/* .balance {
  font-size: 1.35em;
} */
/* Hides gray background when clicking v-card */
.v-card--link:before {
  background: none;
}

.list-slide-item {
  transition: all 0.5s;
  display: inline-block;
}
.list-slide-enter,
.list-slide-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
.list-slide-leave-active {
  position: absolute;
}

.test-card-text a {
  color: var(--v-brand-base);
}

.test-card-text a:hover {
  color: #3240a7;
}
</style>
