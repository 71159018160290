<template>
  <div class="full-height" rounded="0">
    <div class="d-flex flex-column justify-space-between full-height pt-6">
      <v-row justify="center" align="center" class="full-width" no-gutters>
        <v-col cols="11" sm="11" md="5" lg="5" xl="4">
          <div class="rounded-lg py-5">
            <v-card elevation="0" rounded="2" class="main-card text-left">
              <div class="main-card-content mx-8" v-if="loading.page">
                <!-- <v-progress-circular
                  :size="60"
                  :width="5"
                  color="brandCyan"
                  indeterminate
                  class="my-5"
                ></v-progress-circular> -->
                <p class="py-2 mx-10 text-left">
                  Please wait while we load the file.
                </p>
              </div>
              <div class="main-card-content mx-8" v-else>
                <div class="d-flex justify-space-between align-start">
                  <v-card-title class="font-weight-bold pl-0 word-break">
                    File Viewer
                  </v-card-title>
                  <v-btn
                    icon
                    v-if="$auth.isAuthenticated"
                    title="Close"
                    class="mt-3"
                    @click="exit"
                    ><v-icon>mdi-close-circle</v-icon></v-btn
                  >
                </div>
                <div>
                  <div class="d-flex flex-column align-center mt-9">
                    <v-img
                      v-if="data.signedUrl && !data.imageError"
                      :src="data.signedUrl"
                      contain
                      width="100%"
                      height="auto"
                      max-height="275"
                      class="mb-8"
                      v-on:error="failedToLoadImage"
                    />
                    <v-btn
                      depressed
                      rounded
                      color="brandCyan"
                      class="white--text"
                      width="150"
                      :disabled="!data.signedUrl"
                      @click="downloadFile"
                      ><v-icon>mdi-download</v-icon>Download</v-btn
                    >
                  </div>
                </div>
              </div>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" align="start" class="full-width mt-3" no-gutters
        ><v-col>
          <span class="darkGrey--text"
            >{{ "Forms" }} | Powered by
            <a
              class="font-weight-bold darkGrey--text cursor-pointer text-decoration-none"
              href="https://wewhistle.com"
              target="_blank"
              >Whistle</a
            ></span
          >
        </v-col></v-row
      >
    </div>
    <!-- Dialog used while waiting on API response -->
    <v-dialog
      v-if="dialog.loadingAPI"
      v-model="dialog.loadingAPI"
      persistent
      width="500"
    >
      <v-card
        rounded="0"
        class="d-flex justify-center align-center flex-column py-6 px-10"
      >
        <div class="d-flex justify-space-between align-center">
          <v-card-title class="word-break text-center">
            {{
              dialog.loadingAPIErrorMessage
                ? "Error loading the file"
                : "Loading your file..."
            }}
          </v-card-title>
        </div>
        <div
          v-if="dialog.loadingAPIErrorMessage"
          class="word-break text-left mx-6 my-6"
        >
          {{ dialog.loadingAPIErrorMessage }}
        </div>
        <div v-else>
          <v-progress-circular
            :size="60"
            :width="5"
            color="brandCyan"
            indeterminate
            class="my-5"
          ></v-progress-circular>
          <p class="py-2 mx-10 text-left">
            Please wait while we load your file.
          </p>
        </div>
        <!-- Buttons -->
        <div v-if="dialog.loadingAPIErrorMessage">
          <v-card-actions class="mx-8">
            <v-btn
              color="brandCyan"
              outlined
              :width="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 130 : 170
              "
              @click="exit"
              >Exit</v-btn
            >
            <v-btn
              v-if="!dialog.loadingAPI404"
              color="brandCyan"
              :width="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 130 : 170
              "
              depressed
              @click="loadFormSubmissionFile"
              class="white--text"
              >Retry</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FormService from "@/services/FormService";

import { mapState } from "vuex";

function initialState(preloadState = {}) {
  return {
    formSubmissionId: null,
    // Protect this attribute (Don't modify it) because if the form is reloaded, this object is treated as the API response
    form: null,
    loading: {
      page: false
    },
    dialog: {
      loadingAPI: false,
      loadingAPI404: false,
      loadingAPIErrorMessage: ""
    },
    data: {
      signedUrl: null,
      imageError: false
    },
    input: {},
    answers: [],

    // Routing
    previousRoute: null,

    ...preloadState
  };
}
export default {
  name: "FormFileViewer",
  title: "Whistle | Form Files",
  components: {},
  props: {},
  data() {
    return initialState();
  },
  mounted() {
    this.prefillForm(true);
  },
  beforeDestroy() {},
  beforeRouteEnter(to, from, next) {
    next(vm => (vm.previousRoute = from));
  },
  methods: {
    exit() {
      // Added this so Router keeps previous query params if coming from programs
      if (this.previousRoute?.name === "programs") this.$router.back();
      else
        this.$router.push({
          name: "programs",
          query: {
            filterBy: "forms"
          }
        });
    },
    resetForm() {
      // This is so we don't need to do a fresh pull of the form when they want to resubmit something
      Object.assign(this.$data, initialState());
      this.prefillForm();
    },
    prefillForm(freshLoad = false) {
      console.log(this.$route.params);
      this.formSubmissionId = Number(this.$route.params.formSubmissionId);
      if (freshLoad) this.loadFormSubmissionFile();
    },
    async loadFormSubmissionFile() {
      this.loading.page = true;
      this.dialog.loadingAPI = true;
      this.dialog.loadingAPIErrorMessage = "";
      this.dialog.loadingAPI404 = false;
      try {
        const res = await FormService.getFormSubmissionFileV2(
          this.formSubmissionId,
          undefined,
          {
            includeQuestions: true
          }
        );
        console.log("Got signed url ", res?.result?.url);
        this.data.signedUrl = res?.result?.url;
        if (!this.data.signedUrl) throw { status: 404 };

        this.dialog.loadingAPI = false;
      } catch (e) {
        console.log("Failed to load file", e);
        if (e?.response?.status === 404 || e?.status === 404) {
          this.dialog.loadingAPI404 = true;
          this.dialog.loadingAPIErrorMessage =
            "We couldn't find the file you're trying to view. Please try again or contact us if you need further assistance.";
        } else
          this.dialog.loadingAPIErrorMessage =
            "There was an issue loading your file. Please try again or contact us if you need further assistance.";
      } finally {
        this.loading.page = false;
      }
    },
    failedToLoadImage(e) {
      console.log("Failed to load image");
      this.data.imageError = true;
    },
    downloadFile() {
      if (!this.data.signedUrl) return;
      window.open(this.data.signedUrl, "_blank");
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "postItArray"]),
    FlatAnswers() {
      return this.answers.flat().filter(Boolean);
    },
    ValidAnswers() {
      return !this.FlatAnswers.find(a => a.required && !a.textValue);
    }
  }
};
</script>

<style scoped>
/* Controls styling for the card component in
the main container. Shows survey card */
.main-card {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px 10px 10px 10px !important;
  height: auto;
  min-height: 50vh;
}

/* Inner content for main card so it has smaller margins when on mobile*/
.main-card-content {
  min-width: 60%;
  height: 100%;
  min-height: 60vh;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media screen and (min-width: 960px) {
  .main-card-content {
    margin-left: 10%;
    margin-right: 10%;
  }
}
@media screen and (min-width: 451px) {
  .main-card-content {
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 450px) {
  .main-card-content {
    margin-left: 0px;
    margin-right: 0px;
  }
}
</style>
