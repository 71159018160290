//#########################
import Vue from "vue";
import { noAuth as axios } from "../axios";
// import createAuth0Client from "@auth0/auth0-spa-js";
// import { Auth0Client } from "@auth0/auth0-spa-js";
import router from "@/router/index.js";
import UserService from "@/services/UserService.js";
import VueJwtDecode from "vue-jwt-decode";

import auth0Js from "auth0-js";

// import Auth0Lock from "auth0-lock";
// const lockOptions = {
//   theme: {
//     logo:
//       "https://storage.googleapis.com/whistle-dev-pictures/Logo/whistle-logo-gradient-clean.png",
//     primaryColor: "#09ACEC",
//   },
// };

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

let instance;

let randomNum = (Math.random() * 1000).toFixed(2);
let debugUserId =
  process.env.ENV == "prod"
    ? "auth0|5fd7ad86471e5d006f33c701"
    : process.env.ENV == "test"
    ? "auth0|6046305d3fe3250069f574a6"
    : "auth0|60423cf7b5d39d0070481ae6";

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  //eslint-disable-next-line
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        step: null,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        auth0ClientV2: null,
        auth0Lock: null,
        popupOpen: false,
        error: null,
        errorType: null,
        loginRequired: false,
        loginErrorMessage: null,
        loginErrorEmail: null,
        loginErrorPhone: null
      };
    },
    methods: {
      async loginV2(username, password, appState = null) {
        // Uses Auth0.js to log in with embedded
        var p = new Promise((resolve, reject) => {
          let params = {
            audience: options.audience,
            username,
            password,
            realm: process.env.VUE_APP_ENVIRONMENT,
            redirectUri: redirectUri,
            appState: appState,
            scope: "openid profile email",
            leeway: 300 // 5 minutes
          };
          if (process.env.VUE_APP_DEV_LOCAL) params.prompt = "consent";
          this.auth0ClientV2.login(params, async (err, result) => {
            if (err) {
              console.log("Got Auth0 login error ", err);
              reject(err);
              // throw err;
            } else if (result) {
              console.log("Got Auth0 login response ", result);
              if (result.accessToken)
                await options.store.dispatch("setToken", result.accessToken);
              resolve(result);
            }
          });
        });

        return await p
          .then(res => {
            return res;
          })
          .catch(err => {
            throw err;
          });
      },
      async sendPasswordlessEmail(email) {
        // Uses Auth0.js to log in with embedded
        var p = new Promise((resolve, reject) => {
          this.auth0ClientV2.passwordlessStart(
            { email, connection: "email", send: "code" },
            async (err, result) => {
              if (err) {
                console.log("Got Auth0 passwordless email error ", err);
                reject(err);
                // throw err;
              } else if (result) {
                console.log("Got Auth0 passwordless email response ", result);
                // if (result.accessToken)
                // await options.store.dispatch("setToken", result.accessToken);
                resolve(result);
              }
            }
          );
        });

        return await p
          .then(res => {
            return res;
          })
          .catch(err => {
            throw err;
          });
      },
      async submitPasswordlessEmailCode(
        email,
        verificationCode,
        appState = null
      ) {
        // Uses Auth0.js to log in with embedded
        var p = new Promise((resolve, reject) => {
          let params = {
            email,
            connection: "email",
            verificationCode,
            appState: appState
          };
          if (process.env.VUE_APP_DEV_LOCAL) params.prompt = "consent";
          this.auth0ClientV2.passwordlessLogin(params, async (err, result) => {
            if (err) {
              console.log("Got Auth0 passwordless login error ", err);
              reject(err);
              // throw err;
            } else if (result) {
              console.log("Got Auth0 passwordless login response ", result);
              // if (result.accessToken)
              // await options.store.dispatch("setToken", result.accessToken);
              resolve(result);
            }
          });
        });

        return await p
          .then(res => {
            return res;
          })
          .catch(err => {
            throw err;
          });
      },
      async sendPasswordlessSMS(phone_number) {
        // Uses Auth0.js to log in with embedded
        var p = new Promise((resolve, reject) => {
          this.auth0ClientV2.passwordlessStart(
            { phone_number, connection: "sms", send: "code" },
            async (err, result) => {
              if (err) {
                console.log("Got Auth0 passwordless sms error ", err);
                reject(err);
                // throw err;
              } else if (result) {
                console.log("Got Auth0 passwordless sms response ", result);
                // if (result.accessToken)
                // await options.store.dispatch("setToken", result.accessToken);
                resolve(result);
              }
            }
          );
        });

        return await p
          .then(res => {
            return res;
          })
          .catch(err => {
            throw err;
          });
      },
      async submitPasswordlessSMSCode(
        phoneNumber,
        verificationCode,
        appState = null
      ) {
        // Uses Auth0.js to log in with embedded
        var p = new Promise((resolve, reject) => {
          let params = {
            phoneNumber,
            connection: "sms",
            verificationCode,
            appState: appState
          };
          if (process.env.VUE_APP_DEV_LOCAL) params.prompt = "consent";
          this.auth0ClientV2.passwordlessLogin(params, async (err, result) => {
            if (err) {
              console.log("Got Auth0 passwordless sms login error ", err);
              reject(err);
              // throw err;
            } else if (result) {
              console.log("Got Auth0 passwordless sms login response ", result);
              // if (result.accessToken)
              // await options.store.dispatch("setToken", result.accessToken);
              resolve(result);
            }
          });
        });

        return await p
          .then(res => {
            return res;
          })
          .catch(err => {
            throw err;
          });
      },
      async loginWithSAML(username, appState = null) {
        // Uses Auth0.js to log in with embedded
        var p = new Promise((resolve, reject) => {
          this.auth0ClientV2.authorize(
            {
              username,
              connection:
                process.env.VUE_APP_AUTH0_CUSTOM_SOCIAL_CONNECTION || "Other",
              realm: process.env.VUE_APP_ENVIRONMENT,
              appState: appState,
              redirectUri: redirectUri
            },
            async (err, result) => {
              if (err) {
                console.log("Got Auth0 login error ", err);
                reject(err);
                // throw err;
              } else if (result) {
                console.log("Got Auth0 login response ", result);
                if (result.accessToken)
                  await options.store.dispatch("setToken", result.accessToken);
                resolve(result);
              }
            }
          );
        });

        return await p
          .then(res => {
            return res;
          })
          .catch(err => {
            throw err;
          });
      },
      async loginWithOkta(username, appState = null) {
        // Uses Auth0.js to log in with embedded
        var p = new Promise((resolve, reject) => {
          this.auth0ClientV2.authorize(
            {
              username,
              connection: "Okta",
              realm: process.env.VUE_APP_ENVIRONMENT,
              appState: appState,
              redirectUri: redirectUri
            },
            async (err, result) => {
              if (err) {
                console.log("Got Auth0 login error ", err);
                reject(err);
                // throw err;
              } else if (result) {
                console.log("Got Auth0 login response ", result);
                if (result.accessToken)
                  await options.store.dispatch("setToken", result.accessToken);
                resolve(result);
              }
            }
          );
        });

        return await p
          .then(res => {
            return res;
          })
          .catch(err => {
            throw err;
          });
      },
      /**
       * Login with generic third-party auth solution
       * @param {*} username
       * @param {*} appState
       * @returns
       */
      async loginWithGeneric3PA(connection, username, appState = null) {
        // Uses Auth0.js to log in with embedded

        console.log("Signing in with connection ", { connection, appState });
        console.log("WINDOW ", window.location);
        var p = new Promise((resolve, reject) => {
          (connection == "procore"
            ? this.auth0ClientV2.popup
            : this.auth0ClientV2
          ).authorize(
            {
              username,
              connection: connection,
              realm: process.env.VUE_APP_ENVIRONMENT,
              appState: appState,
              redirectUri:
                connection == "procore"
                  ? `${window.location.origin}/popup/callback`
                  : redirectUri
            },
            async (err, result) => {
              if (err) {
                console.log("Got Auth0 login error ", err);
                reject(err);
                // throw err;
              } else if (result) {
                console.log("Got Auth0 login response ", result);
                if (result.accessToken)
                  await options.store.dispatch("setToken", result.accessToken);
                if (result.accessToken && connection == "procore")
                  window.location.reload();
                resolve(result);
              }
            }
          );
        });

        return await p
          .then(res => {
            return res;
          })
          .catch(err => {
            throw err;
          });
      },
      async getMFAAuthenticators(token) {
        // Uses Auth0.js to log in with embedded
        var opt = {
          method: "GET",
          url: `https://${options.domain}/mfa/authenticators`,
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${token}`
          }
        };
        // var tempAxios = axios;
        // delete tempAxios.defaults.headers.common;
        return await axios
          .request(opt)
          .then(res => {
            console.log("MFA authenticators ", res);
            return res.data;
          })
          .catch(err => {
            console.log("Error getting MFA authenticators", err);
            throw err;
          });
      },
      async challengeMFA(authId, token) {
        // Uses Auth0.js to log in with embedded
        var p = new Promise((resolve, reject) => {
          var opt = {
            method: "POST",
            url: `https://${options.domain}/mfa/challenge`,
            headers: {
              "content-type": "application/json"
              // authorization: `Bearer ${token}`,
            },
            data: {
              client_id: options.clientId,
              challenge_type: "oob",
              authenticator_id: authId,
              mfa_token: token
            }
          };
          // var tempAxios = axios;
          // delete tempAxios.defaults.headers.common;
          axios
            .request(opt)
            .then(mfaResponse => {
              console.log("MFA Chalenge ", mfaResponse.data);
              resolve(mfaResponse.data);
            })
            .catch(err => {
              console.log(err);
              reject(err);
            });
        });

        return await p
          .then(res => {
            return res;
          })
          .catch(err => {
            throw err;
          });
      },
      async submitMFACode(oobCode, bindingCode, token) {
        // We want to submit the MFA code and then redirect just to be safe

        var p = new Promise((resolve, reject) => {
          var opt = {
            method: "POST",
            url: `https://${options.domain}/oauth/token`,
            headers: { "content-type": "application/x-www-form-urlencoded" },
            data: new URLSearchParams({
              // grant_type: "http://auth0.com/oauth/grant-type/mfa-oob",
              // client_id: "YOUR_CLIENT_ID",
              // client_secret: "YOUR_CLIENT_SECRET",
              // mfa_token: "MFA_TOKEN",
              // oob_code: "OOB_CODE",
              // binding_code: "USER_OTP_CODE",
              grant_type: "http://auth0.com/oauth/grant-type/mfa-oob",
              client_id: options.clientId,
              mfa_token: token,
              oob_code: oobCode,
              binding_code: bindingCode
            })
          };

          // var tempAxios = axios;
          // delete tempAxios.defaults.headers.common;
          axios
            .request(opt)
            .then(async mfaResponse => {
              console.log("Login after MFA ", mfaResponse.data);
              //
              if (mfaResponse.data.accessToken)
                await options.store.dispatch(
                  "setToken",
                  mfaResponse.data.accessToken
                );
              resolve(mfaResponse.data);
            })
            .catch(err => {
              console.log(err);
              reject(err);
            });
        });

        return await p
          .then(res => {
            return res;
          })
          .catch(err => {
            throw err;
          });
      },
      async registerMFA(phone, token) {
        // Uses Auth0.js to log in with embedded
        var p = new Promise((resolve, reject) => {
          var opt = {
            method: "POST",
            url: `https://${options.domain}/mfa/associate`,
            headers: {
              "content-type": "application/json",
              authorization: `Bearer ${token}`
            },
            data: {
              authenticator_types: ["oob"],
              oob_channels: ["sms"],
              phone_number: phone
              // mfa_token: token,
            }
          };
          console.log("MFA opt", opt);
          // var tempAxios = axios;
          // delete tempAxios.defaults.headers.common;
          axios
            .request(opt)
            .then(mfaResponse => {
              console.log("MFA Association ", mfaResponse.data);
              resolve(mfaResponse.data);
            })
            .catch(err => {
              console.log(err);
              reject(err);
            });
        });

        return await p
          .then(res => {
            return res;
          })
          .catch(err => {
            throw err;
          });
      },
      markAsUnauthenticated() {
        this.isAuthenticated = false;
      },
      loginWithEmbedded(o) {
        // We want to route to the Login page
        if (
          router.app.$route.name != "login" &&
          router.app.$route.name != "spirit-login" &&
          router.app.$route.name != "generic-3pa-login"
        )
          router.app.$router.push({
            name: "login",
            params: o
          });
        options.store.state.failedLogin = false;
        this.loading = false;
        return;
        // return this.auth0Client.loginWithRedirect(o);
      },
      async checkSession() {
        // Uses Auth0.js to log in with embedded
        var p = new Promise((resolve, reject) => {
          this.auth0ClientV2.checkSession(
            {
              audience: options.audience,
              scope: "openid profile email",
              realm: process.env.VUE_APP_ENVIRONMENT,
              leeway: 300 // 5 minutes
            },
            async (err, result) => {
              if (err) {
                console.log("Got Auth0 check session error ", err);
                reject(err);
                // throw err;
              } else if (result) {
                console.log("Got Auth0 check session response ", result);
                // if (result.accessToken)
                //   await options.store.dispatch("setToken", result.accessToken);
                resolve(result);
              }
            }
          );
        });

        return await p
          .then(res => {
            return res;
          })
          .catch(err => {
            throw err;
          });
      },
      async parseHash(hash) {
        // Uses Auth0.js to log in with embedded
        var p = new Promise((resolve, reject) => {
          this.auth0ClientV2.parseHash({ hash }, async (err, authResult) => {
            if (err) {
              console.log("AUTH HASH ERROR ", err);
              reject(err);
              // return router.app.$router.push("/login");
            } else {
              console.log("PARSED AUTH HASH ", authResult);
              // await options.store.dispatch("setToken", authResult.accessToken);
              resolve(authResult);
            }

            // webAuth.client.userInfo(authResult.accessToken, function(err, user) {
            // Now you have the user's information
          });
        });

        return await p
          .then(res => {
            return res;
          })
          .catch(err => {
            throw err;
          });
      },
      async isUserAuthenticated(token) {
        if (!token) return false;
        const user = await this.getAuthUser(token);
        return !!user;
      },
      async getAuthUser(token) {
        // try {
        if (!token) return null;
        return await new Promise((resolve, reject) => {
          this.auth0ClientV2.client.userInfo(token, (err, user) => {
            if (err) {
              reject(err);
            } else {
              resolve(user);
            }
          });
        });
      },

      /** Authenticates the user using the redirect method */
      // loginWithRedirect(o) {
      //   return this.auth0Client.loginWithRedirect(o);
      // },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      async getTokenSilently() {
        var token = await this.checkSession();
        return token.accessToken;
        // if (token.accessToken)
        //   await options.store.dispatch("setToken", token.accessToken);
        //   return;
        // return this.auth0Client.getTokenSilently(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        if (this.auth0ClientV2) {
          console.log("Logging out of Auth0");
          this.auth0ClientV2.logout(o);
        }
        if (this.auth0Client) this.auth0Client.logout(o);
        options.store.dispatch("logout");
        // If no client exists we're using magic links so we want to redirect them to the login screen

        if (!this.auth0ClientV2) router.app.$router.push("/login");
        return;
        // return
      },
      storeDebugLog(key, value) {
        var sup = {
          userId: debugUserId,
          key: randomNum + key,
          value: value
        };
        UserService.createDebugUserSupplemental(sup).catch(error => {
          console.log("Error creating user sup ", error);
        });
      }
      // showLock() {
      //   this.auth0Lock.show(lockOptions);
      // },
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // var authOpt = {
      //   domain: options.domain,
      //   client_id: options.clientId,
      //   audience: options.audience,
      //   cacheLocation: "localstorage",
      //   redirect_uri: redirectUri,
      //   authorizeTimeoutInSeconds: 20,
      //   httpTimeoutInSeconds: 20,
      //   useRefreshTokens: true
      // };

      // Create a new instance of the SDK client using members of the given options object
      // console.log("Going to reset state");
      await options.store.dispatch("resetState");
      console.log("Done resetting state");

      // We first need to check if we're dealing with a magic link
      // If so, then we want to ignore all Auth0 calls
      var magicLinkRoute = null;
      var magicLinkToken = null;
      if (router.app.$route.name == "magic-link") {
        console.log("We dealing with a magic link!", router.app.$route);
        // Now we fetch the magic link data
        try {
          let magicLinkRes = await options.store.dispatch("setMagicLinkToken", {
            magicLinkId: router.app.$route.params.magicLinkId,
            magicLink: router.app.$route.params.magicLink
          });
          console.log("Got magic link res", magicLinkRes);
          magicLinkToken = magicLinkRes;
          if (magicLinkRes.magicLink)
            magicLinkRoute = magicLinkRes.magicLink.redirectUri;
        } catch (err) {
          console.log("Error getting magic link token ", err);
          if (err.redirect_uri) magicLinkRoute = err.redirect_uri;
          else if (err.redirectUri) magicLinkRoute = err.redirectUri;

          if (err.email) this.loginErrorEmail = err.email;
          if (err.phone) this.loginErrorPhone = err.phone;

          if (err.error_code && typeof err.error_code == "string") {
            // Now we send friendly messages based on the few possible errors
            const errorCode = err.error_code;
            switch (errorCode) {
              // case "1030200":
              //   this.loginErrorMessage = null;
              //   break;
              // 201 = Magic link used
              case "1030201":
                this.loginErrorMessage =
                  "The link provided is only valid once. Please log in to continue.";
                break;
              // 202 = Magic link expired
              case "1030202":
                this.loginErrorMessage =
                  "The link provided expired. Please log in to continue.";
                break;
              // 203 = Magic link invalid (couldn't decrypt)
              case "1030203":
                this.loginErrorMessage =
                  "The link provided appears to be invalid. Please log in to continue.";
                break;
              // // 204 = Client disabled magic links
              // case "1030204":
              //   this.loginErrorMessage =
              //     "The link provided appears to be invalid. Please log in to continue.";
              //   break;
              // 205 = Balance too large
              // case "1030205":
              //   this.loginErrorMessage =
              //     "The link provided appears to be invalid. Please log in to continue.";
              //   break;
            }
          }
        }

        onRedirectCallback({ targetUrl: magicLinkRoute || "/" });
      }

      // this.storeDebugLog(
      //   "_Vue_Auth0_Reset_State_After",
      //   JSON.stringify(authOpt)
      // );

      // .then(async () => {
      // We set the wallet menu to close.
      // This handles the case when a user refreshes the page with the wallet open.
      // Our wallet window refreshes the balance on open, so if they refresh and it's already open, their balance stays 0
      // options.store.dispatch("setDisplayWalletMenu", false);

      // this.auth0Client = await createAuth0Client(authOpt);
      if (window.location.pathname == "/popup/callback") {
        try {
          console.log("POPUP CALLBACK");
          const client = new auth0Js.WebAuth({
            domain: options.domain,
            clientID: options.clientId
          });
          return client.popup.callback({
            hash: window.location.hash
          });
        } catch (e) {
          console.log("error popup callback", e);
          throw e;
        }
      }

      this.step = "getting client";
      this.auth0ClientV2 = new auth0Js.WebAuth({
        domain: options.domain,
        clientID: options.clientId,
        audience: options.audience,
        redirectUri: redirectUri,
        // scope: params.scope,
        responseType: "token id_token",
        leeway: 300 // 5 minutes
      });
      this.step = "got client";
      if (!magicLinkToken) {
        // this.auth0Client = new Auth0Client(authOpt);
        console.log("Window hash", window.location.hash);
        try {
          if (
            window.location.hash &&
            window.location.hash.includes("access_token=")
          ) {
            this.step = "hash";
            // We're getting redirected so we parse access token and store
            var result = await this.parseHash(window.location.hash);
            console.log("Hash result ", result);
            if (result.accessToken)
              await options.store.dispatch("setToken", result.accessToken);

            const { appState } = result;
            // const appState = {
            //   targetUrl: "/profile",
            // };
            onRedirectCallback(appState);
            // this.auth0ClientV2.parseHash(
            //   { hash: window.location.hash },
            //   async (err, authResult) => {
            //     if (err) {
            //       console.log("AUTH HASH ERROR ", err);
            //       return router.app.$router.push("/login");
            //     }

            //     console.log("PARSED AUTH HASH ", authResult);
            //     await options.store.dispatch("setToken", authResult.accessToken);

            //     this.loading = false;

            //     // webAuth.client.userInfo(authResult.accessToken, function(err, user) {
            //     // Now you have the user's information
            //   }
            // );
            // });
          } else {
            this.step = "no hash - check session";
            // if you do this, you'll need to check the session yourself
            var token = await this.checkSession();
            if (token.accessToken)
              await options.store.dispatch("setToken", token.accessToken);
          }
        } catch (error) {
          this.step = "catch error 1";
          console.log("Auth0 client catch error! ", error);
          this.storeDebugLog(
            "_Vue_Auth0_Failed_Session_Check" + error.error || "",
            error.error
              ? JSON.stringify(error)
              : JSON.stringify(error, Object.getOwnPropertyNames(error))
          );

          if (!error.error) {
            // Used to know if for some reason we failed to parse the JSON in the error
            var parseError = false;
            try {
              let errObj = JSON.parse(
                JSON.stringify(error, Object.getOwnPropertyNames(error))
              );
              if (
                errObj.message &&
                errObj.message.startsWith(
                  "Expiration Time (exp) claim error in the ID token"
                )
              ) {
                console.log("Time error!!!");
                this.errorType = "clock";
              }
            } catch (parseErr) {
              console.log("Error parsing error ", parseErr);
              parseError = true;
            }
            // The clock error changed when we switched to embedded login
          } else if (
            error.error == "invalid_token" &&
            error.errorDescription &&
            error.errorDescription.startsWith(
              "Expiration Time (exp) claim error in the ID token"
            )
          ) {
            console.log("Time error!!!");
            this.errorType = "clock";
          }
          if (this.errorType) throw error;
          else if (error.error !== "login_required") {
            this.storeDebugLog(
              "_Vue_Auth0_Force_Logout" + error.error || "",
              "Error empty or !== login_required. Failed parsing - " +
                parseError
            );
            //force logout
            this.logout();
          } else this.loginRequired = true;
        }

        // this.storeDebugLog(
        //   "_Vue_Auth0_Client_Creation_After",
        //   "Initialized Auth0 Client"
        // );

        console.log("Auth0 client created");
      }
      try {
        // If the user is returning to the app after authentication..
        // if (
        //   window.location.search.includes("code=") &&
        //   window.location.search.includes("state=")
        // ) {
        //   console.log("URL includes code & state");
        //   // throw window.location;
        //   // handle the redirect and retrieve tokens
        //   // this.storeDebugLog(
        //   //   "_Vue_Auth0_Handle_Redirect_Before",
        //   //   "Before auth0 handleRedirectCallback"
        //   // );
        //   const { appState } = await this.auth0Client.handleRedirectCallback();
        //   console.log("got app state after handleRedirectCallback");
        //   // this.storeDebugLog(
        //   //   "_Vue_Auth0_Handle_Redirect_After",
        //   //   "After auth0 handleRedirectCallback"
        //   // );
        //   // Notify subscribers that the redirect callback has happened, passing the appState
        //   // (useful for retrieving any pre-authentication state)
        //   onRedirectCallback(appState);
        //   // this.storeDebugLog(
        //   //   "_Vue_Auth0_On_Redirect",
        //   //   "After Auth0 onRedirectCallback"
        //   // );
        // } else {
        //   // this.storeDebugLog(
        //   //   "_Vue_Auth0_Missing_Code_or_State",
        //   //   JSON.stringify(window.location)
        //   // );
        // }
      } catch (e) {
        this.step = "error catch 2";
        this.storeDebugLog(
          "_Vue_Auth0_Login_Error_Catch",
          e.error
            ? JSON.stringify(e)
            : JSON.stringify(e, Object.getOwnPropertyNames(e))
        );
        console.log("We had an error inside auth0!!! ", e);
        options.store.dispatch("setErrorString", e);
        this.error = e;
      } finally {
        this.step = "finally";
        console.log("INSIDE FINALLY OF AUTH.JS");
        // Initialize our internal authentication state
        // this.isAuthenticated = await this.isUserAuthenticated(
        //   options.store.state.token
        // );

        this.storeDebugLog("_Vue_Auth0_In_Finally");
        // if (!this.isAuthenticated && router.app.$route.name != "login")
        //   router.app.$router.push("/login");
        this.user = null;
        // We hit this top block if we're logging in via magic link
        if (magicLinkToken) {
          console.log(magicLinkToken);
          this.user = VueJwtDecode.decode(magicLinkToken.token.access_token);
        } else if (!this.loginRequired) {
          try {
            this.user = await this.getAuthUser(options.store.state.token);
          } catch (err) {
            console.log("error getting auth user ", err);
            this.storeDebugLog(
              "_Vue_Auth0_User_Catch",
              err.error
                ? JSON.stringify(err)
                : JSON.stringify(err, Object.getOwnPropertyNames(err))
            );
          }
        }
        this.isAuthenticated = this.user ? true : false;
        this.step = "updated isAuthenticated";
        console.log("Updated isAuthenticated ", this.isAuthenticated);
        console.log("Auth0 User ", this.user);
        this.storeDebugLog("_Vue_Auth0_Login_User", JSON.stringify(this.user));
        //once we have a user, get permissions / user data
        if (this.user) {
          this.step = "user exists";
          if (
            // If it's a magic link, then we don't preload
            !magicLinkToken &&
            // If they're coming from a previous login error, we want to get the profile fresh
            router.app.$route.name != "login-error" &&
            options.store.state.userProfile &&
            // If they're a recently new user and it's cached as first login, we make them wait
            !options.store.state.userProfile.firstLogin &&
            (options.store.state.userProfile.businessEmail ||
              options.store.state.userProfile.businessPhone) &&
            // TO DO - we need to add a check here that we can preload magic link if the userProfile email matches the token email
            options.store.state.userProfile.userId &&
            !options.store.state.userProfile.userId.startsWith("tmp|") &&
            options.store.state.userProfile.userId == this.user.sub
          ) {
            this.step = "loading cached profile";
            console.log(
              "Loading app early with cached profile ",
              JSON.parse(JSON.stringify(options.store.state.userProfile))
            );
            this.loading = false;
          } else console.log("We can't load app early");

          this.step = "getting userprofile";
          await options.store.dispatch("setUser", this.user.sub);
          this.step = "got profile";

          //setting user meta data for smartlook
          // vaosmartlook("identify", this.user.sub, smartLookUser);
          if (this.loading == true) {
            this.step = "got profile and marking loading";
            console.log("Set loading = false after getting profile");
            this.loading = false;
          }
          options.store.dispatch("setClients");
          options.store.dispatch("setMarqeta");
          options.store.dispatch("setUnreadMessages");

          /* chameleon.io NPM script :DDD  */

          // const chmln = require("@chamaeleonidae/chmln");
          // chmln.init(
          //   "Sg2pNLNXRyMIgEEo6O6AcxYOZ8XGIrFlUawl4ePH9syouI-1QbIXI-EtJs3ThWhkZTJvOE",
          //   { fastUrl: "https://fast.chameleon.io/" }
          // );

          // console.log("CHAMELEON IS HERE");
          /* chameleon.io user identification and data */

          // var isNew = false;

          // //get the earliest Privacy Policy signed
          // const privacyAgreements = options.store.state.userProfile.UserSupplementals.filter(
          //   item =>
          //     item.key === "Privacy Policy Agreement" && item.value != null
          // );

          // if (privacyAgreements.length > 0) {
          //   const earliestPrivacy = privacyAgreements.sort((a, b) => {
          //     const dateA = new Date(a.value);
          //     const dateB = new Date(b.value);
          //     return dateA - dateB;
          //   });

          //   var varDate = new Date(earliestPrivacy[0].value);
          //   var threshold = new Date("2023-09-29");

          //   if (varDate >= threshold) {
          //     //Do something..
          //     isNew = true;
          //   }
          // } else {
          //   isNew = true; //on first login you haven't accepted privacy policy yet, so it won't exist in user supplemental, but we know that you are new
          // }

          // console.log("isNew: " + isNew);

          // chmln.identify(this.user.sub, {
          //   // REQUIRED, the unique ID of each user in your database (e.g. 23443 or "690b80e5f433ea81b96c9bf6")
          //   email: options.store.state.userProfile.businessEmail, // RECOMMENDED, email is used as the key to map user data for integrations
          //   name:
          //     options.store.state.userProfile.firstName +
          //     " " +
          //     options.store.state.userProfile.lastName, // RECOMMENDED, name can be used to greet and/or personalize content
          //   isNewAsOf9_29_23: isNew,
          //   clientId: options.store.state.userProfile.Client.clientId
          // });
        } else {
          this.step = "no user";
          // We don't want to hit this code if the user has a login bug
          // We have to check the router path. if they were going to unsubscribe, then we don't redirect them to login
          // Or a public survey
          // Or rendering the success animation for card activation
          // Or virtual card hosted
          if (
            router.app.$route.name != "emailunsubscribe" &&
            router.app.$route.name != "surveyPublic" &&
            router.app.$route.name != "walletactivationsuccess" &&
            router.app.$route.name != "walletpinsuccess" &&
            router.app.$route.name != "marqetavirtualcardhosted" &&
            router.app.$route.name != "marqetaactivatecardhosted" &&
            router.app.$route.name != "marqetacreatepinhosted" &&
            router.app.$route.name != "setup" &&
            router.app.$route.name != "slacksetup" &&
            router.app.$route.name != "user-signup" &&
            router.app.$route.name != "user-signup-static" &&
            router.app.$route.name != "spirit-login" &&
            router.app.$route.name != "generic-3pa-login" &&
            router.app.$route.name != "forms"
          ) {
            if (!this.errorType) {
              if (magicLinkRoute) var url = magicLinkRoute;
              else if (
                !(
                  window.location.search.includes("code=") &&
                  window.location.search.includes("state=")
                )
              )
                url = window.location.pathname + window.location.search;
              else url = window.location.pathname;
              if (window.location.pathname.includes("login-error")) url = "/";
              console.log(
                "Forcing redirect with invalid route inside auth.js",
                url
              );
              this.storeDebugLog("_Vue_Auth0_Login_Invalid_Route", url);
              this.loginWithEmbedded({
                appState: {
                  targetUrl: url
                },
                error: this.loginErrorMessage,
                email: this.loginErrorEmail,
                phone: this.loginErrorPhone
              });
              // this.loading = false;
            }
          } else {
            // We set loading to true so we can tell the special components (listed above) that we've verified that the user is not authenticated
            this.loading = false;
            this.storeDebugLog(
              "_Vue_Auth0_Login_Valid_Route_Unauth",
              router.app.$route.name
            );
          }
        }
      }
    }
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  }
};
