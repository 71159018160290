<template>
  <div class="full-height" rounded="0">
    <div class="d-flex flex-column justify-space-between full-height pt-6">
      <v-row justify="center" align="center" class="full-width" no-gutters>
        <v-col cols="11" sm="11" md="5" lg="5" xl="4">
          <div class="rounded-lg py-5">
            <v-card
              v-if="form"
              elevation="0"
              rounded="2"
              class="main-card text-left"
            >
              <div class="main-card-content mx-8">
                <div class="d-flex justify-space-between align-start">
                  <v-card-title class="font-weight-bold pl-0 word-break">
                    {{ form.name }}
                  </v-card-title>
                  <v-btn
                    icon
                    v-if="$auth.isAuthenticated"
                    title="Close"
                    class="mt-3"
                    @click="exit"
                    ><v-icon>mdi-close-circle</v-icon></v-btn
                  >
                </div>
                <transition :name="slideDirection" mode="out-in">
                  <div v-if="currentSlide == keys.form" :key="keys.form">
                    <v-form v-model="validForm" onSubmit="return false;">
                      <p class="mt-4 mb-1">
                        Your info
                      </p>
                      <v-text-field
                        v-model="input.name"
                        autofocus
                        label="Name*"
                        color="brandCyan"
                        class=""
                        outlined
                        dense
                        :rules="formRules.stringRequired(255, 'name')"
                      ></v-text-field>
                      <v-text-field
                        v-model="input.email"
                        :rules="[
                          v => !!v || 'An email or phone number is required',
                          v =>
                            !!(v && v.length < 255) ||
                            'Your email/phone should be shorter',
                          v =>
                            !!(v && (emailRegex.test(v) || validPhone)) ||
                            'Your email/phone does not appear valid'
                        ]"
                        label="Email or Phone*"
                        outlined
                        dense
                        color="brandCyan"
                        @change="phoneValidation"
                        @input="phoneValidation"
                      ></v-text-field>
                      <FormQuestion
                        v-for="(question, i) in form?.FormQuestions?.filter(
                          x => x.topLevelQuestion
                        )"
                        :key="question.formQuestionId"
                        :question="question"
                        :formId="formId"
                        :childQuestions="
                          form?.FormQuestions?.filter(
                            x => !x.topLevelQuestion
                          ) || []
                        "
                        @update-responses="updateAnswerArray($event, i)"
                      ></FormQuestion>
                      <v-file-input
                        v-if="form.allowFileUpload"
                        v-model="input.file"
                        label="Upload a file or photo"
                        outlined
                        clearable
                        hide-details
                        chips
                        show-size
                        color="brandCyan"
                        class="search-field search"
                        prepend-icon
                      >
                      </v-file-input>
                      <p
                        v-if="form.allowFileUpload"
                        class="text-caption ml-2 mt-1"
                        :class="{ 'error--text': FileTooLarge }"
                      >
                        A max file size of 50 MB is supported
                      </p>
                    </v-form>

                    <div class="d-flex flex-column align-center mt-9">
                      <v-btn
                        depressed
                        rounded
                        color="brandCyan"
                        class="white--text"
                        width="120"
                        :disabled="!validForm || !ValidAnswers || FileTooLarge"
                        :loading="loading.submission || loading.submissionFile"
                        @click="submitForm"
                        >Submit</v-btn
                      >
                    </div>
                  </div>
                  <div
                    v-else-if="currentSlide == keys.success"
                    :key="keys.success"
                    class="text-center d-flex flex-column align-center pt-4"
                  >
                    <span class="font-weight-bold text-h5">Great job!</span>
                    <Robin
                      :showText="false"
                      :width="140"
                      :height="170"
                      animation="backflip"
                      :loop="false"
                    />
                    <v-btn
                      rounded
                      depressed
                      class="white--text mt-10"
                      color="brandCyan"
                      width="170"
                      @click="resetForm"
                      >New Entry</v-btn
                    >
                    <v-btn
                      v-if="$auth.isAuthenticated"
                      rounded
                      depressed
                      outlined
                      class="white--text mt-6"
                      color="brandCyan"
                      width="170"
                      @click="exit"
                      >Close</v-btn
                    >
                  </div>
                </transition>
              </div>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" align="start" class="full-width mt-3" no-gutters
        ><v-col>
          <span class="darkGrey--text"
            >{{ "Forms" }} | Powered by
            <a
              class="font-weight-bold darkGrey--text cursor-pointer text-decoration-none"
              href="https://wewhistle.com"
              target="_blank"
              >Whistle</a
            ></span
          >
        </v-col></v-row
      >
    </div>
    <!-- Dialog used while waiting on API response -->
    <v-dialog
      v-if="dialog.loadingAPI"
      v-model="dialog.loadingAPI"
      persistent
      width="500"
    >
      <v-card
        rounded="0"
        class="d-flex justify-center align-center flex-column py-6 px-10"
      >
        <div class="d-flex justify-space-between align-center">
          <v-card-title class="word-break text-center">
            {{
              dialog.loadingAPIErrorMessage
                ? "Error loading the form"
                : "Loading your form..."
            }}
          </v-card-title>
        </div>
        <div
          v-if="dialog.loadingAPIErrorMessage"
          class="word-break text-left mx-6 my-6"
        >
          {{ dialog.loadingAPIErrorMessage }}
        </div>
        <div v-else>
          <v-progress-circular
            :size="60"
            :width="5"
            color="brandCyan"
            indeterminate
            class="my-5"
          ></v-progress-circular>
          <p class="py-2 mx-10 text-left">
            Please wait while we load your form.
          </p>
        </div>
        <!-- Buttons -->
        <div v-if="dialog.loadingAPIErrorMessage">
          <v-card-actions class="mx-8">
            <v-btn
              color="brandCyan"
              outlined
              :width="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 130 : 170
              "
              @click="exit"
              >Exit</v-btn
            >
            <v-btn
              color="brandCyan"
              :width="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 130 : 170
              "
              depressed
              @click="loadForm"
              class="white--text"
              >Retry</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <ErrorDialog
      v-if="dialog.submissionErrorMessage || dialog.submissionFileErrorMessage"
      :title="
        dialog.submissionErrorMessage || dialog.submissionFileErrorMessage
      "
      :persistent="!!dialog.submissionFileErrorMessage"
      subtitle="Support: help@wewhistle.com"
      button1Text="Close"
      @button1="
        dialog.submissionErrorMessage = '';
        dialog.submissionFileErrorMessage = '';
      "
      @close="
        dialog.submissionErrorMessage = '';
        dialog.submissionFileErrorMessage = '';
      "
      button2Text="Retry"
      :button2Loading="loading.submission || loading.submissionFile"
      @button2="
        if (dialog.submissionFileErrorMessage) {
          uploadSubmissionFile();
        } else {
          submitForm();
        }
      "
    />
  </div>
</template>

<script>
import FormService from "@/services/FormService";
import ContentService from "@/services/ContentService";
import UserService from "@/services/UserService";

import FormQuestion from "@/components/forms/FormQuestion";
import Robin from "@/components/Robin";
import ErrorDialog from "@/components/ErrorDialog";

import { formRules, emailRegex } from "@/shared_data/data";

import { mapState } from "vuex";
// import moment from "moment";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";

function initialState(preloadState = {}) {
  return {
    formId: null,
    formSubmissionId: null,
    // Protect this attribute (Don't modify it) because if the form is reloaded, this object is treated as the API response
    form: null,
    loading: {
      form: false,
      submission: false,
      submissionFile: false
    },
    dialog: {
      loadingAPI: false,
      loadingAPIErrorMessage: "",
      submissionErrorMessage: "",
      submissionFileErrorMessage: ""
    },
    currentSlide: 0,
    keys: {
      form: 0,
      success: 1
    },
    validForm: null,
    formRules,
    emailRegex,
    validPhone: false,
    input: {
      name: null,
      email: null,
      phone: null,
      file: null,
      latititude: null,
      longitude: null
    },
    answers: [],

    // Routing
    previousRoute: null,

    // Transitions
    slideDirection: "topic-left",

    // Preloading
    ...preloadState
  };
}
export default {
  name: "Form",
  title: "Whistle | Forms",
  components: {
    FormQuestion,
    Robin,
    ErrorDialog
  },
  props: {},
  data() {
    return initialState();
  },
  mounted() {
    this.prefillForm(true);

    navigator.geolocation.getCurrentPosition(
      position => {
        console.log("Got coordinates");
        this.input.latititude = position.coords.latitude;
        this.input.longitude = position.coords.longitude;
      },
      error => {
        console.log("Failed to get coordinates", error.message);
      }
    );
  },
  beforeDestroy() {},
  beforeRouteEnter(to, from, next) {
    next(vm => (vm.previousRoute = from));
  },
  methods: {
    exit() {
      // Added this so Router keeps previous query params if coming from programs
      if (this.previousRoute?.name === "programs") this.$router.back();
      else
        this.$router.push({
          name: "programs",
          query: {
            filterBy: "forms"
          }
        });
    },
    resetForm() {
      // This is so we don't need to do a fresh pull of the form when they want to resubmit something
      const form = JSON.parse(JSON.stringify(this.form));
      const longitude = JSON.parse(JSON.stringify(this.input.longitude));
      const latititude = JSON.parse(JSON.stringify(this.input.latititude));
      Object.assign(this.$data, initialState({ form }));
      this.input.longitude = longitude;
      this.input.latititude = latititude;
      this.prefillForm();
    },
    prefillForm(freshLoad = false) {
      this.formId = Number(this.$route.params.formId);
      if (freshLoad) this.loadForm();

      if (this.$auth.isAuthenticated) {
        this.input.name =
          this.userProfile?.displayName ||
          this.userProfile?.firstName + " " + this.userProfile?.lastName;
        this.input.email =
          this.userProfile?.businessEmail || this.userProfile?.businessPhone;
        this.input.phone = this.userProfile?.businessPhone;
        if (!this.userProfile?.businessEmail)
          this.phoneValidation(this.input.phone);
      }
    },
    async loadForm() {
      this.loading.form = true;
      this.dialog.loadingAPI = true;
      this.dialog.loadingAPIErrorMessage = "";
      try {
        const res = await FormService.getFormsV2(this.formId, undefined, {
          includeQuestions: true
        });
        console.log("Got form ", res?.result);
        this.form = res?.result;
        if (!this.form) throw { status: 404 };

        this.dialog.loadingAPI = false;
      } catch (e) {
        console.log("Failed to load form", e);
        this.dialog.loadingAPIErrorMessage =
          "There was an issue loading your form. Please try again or contact us if you need further assistance.";
      } finally {
        this.loading.form = false;
      }
    },
    async submitForm() {
      this.loading.submission = true;
      // this.dialog.loadingAPI = true;
      this.dialog.submissionErrorMessage = "";

      let submission = {
        creatorName: this.input.name,
        creatorEmail: this.input.phone
          ? this.userProfile?.businessEmail
          : this.input.email,
        creatorPhone: this.input.phone,
        creatorUserId: this.$auth.isAuthenticated
          ? this.userProfile?.userId
          : null,
        latitude: this.input.latititude,
        longitude: this.input.longitude,
        FormSubmissionValues: this.FlatAnswers.filter(x => x.textValue).map(
          x => {
            delete x.required;
            return x;
          }
        )
      };
      try {
        submission.ipAddress = (await UserService.getUserIpAddress())?.ip;
      } catch (e) {
        console.log("Failed to get IP", e);
      }

      try {
        const res = await FormService.createFormSubmissionV2(
          this.formId,
          submission
        );
        console.log("Created submission ", res?.result);
        this.formSubmissionId = res?.result?.formSubmissionId;
        if (this.input.file && this.formSubmissionId)
          await this.uploadSubmissionFile();
        else this.currentSlide = this.keys.success;
      } catch (e) {
        console.log("Failed to load form", e);
        this.dialog.submissionErrorMessage =
          "There was an issue saving your submission. Please try again or contact us if you need further assistance.";
      } finally {
        this.loading.submission = false;
      }
    },
    async uploadSubmissionFile() {
      this.loading.submissionFile = true;
      this.dialog.submissionFileErrorMessage = "";
      if (!this.formSubmissionId || !this.input.file) return;
      console.log("Uploading file ", { file: this.input.file.name });
      try {
        const fileRes = await FormService.createFormSubmissionFileUploadV2(
          this.formSubmissionId,
          {
            fileName: this.input.file.name || "unknown",
            fileSize: this.input.file.size
          }
        );
        const signedUrl = fileRes?.result?.url;
        console.log("Got signed url for file ", signedUrl);
        if (signedUrl)
          await ContentService.uploadContentWithSignedUrl(
            signedUrl,
            this.input.file,
            2,
            true
          );
        this.currentSlide = this.keys.success;
      } catch (e) {
        console.log("Failed to upload file", e);
        // In this case, we already have content for the submission, so it somehow failed but passed earlier??? This shouldn't happen, but just in case, it's handled as a success
        if (e?.response?.status === 409) this.currentSlide = this.keys.success;
        else if (e?.response?.status === 413)
          this.dialog.submissionFileErrorMessage =
            "The file you submitted was too large. We have a limit of 50 MB per upload.";
        else
          this.dialog.submissionFileErrorMessage =
            "There was an issue uploading your file. Please try again or contact us if you need further assistance.";
      } finally {
        this.loading.submissionFile = false;
      }
    },
    phoneValidation: function(val) {
      let isValid = false;
      isValid = isValidPhoneNumber(val, "US");
      this.validPhone = isValid;
      if (isValid)
        this.input.phone = parsePhoneNumber(val, "US").format("E.164");
      else this.input.phone = null;
    },
    updateAnswerArray(event, index) {
      this.$set(this.answers, index, event.flat());
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "postItArray"]),
    FlatAnswers() {
      return this.answers.flat(Infinity).filter(Boolean);
    },
    ValidAnswers() {
      return !this.FlatAnswers.find(a => a.required && !a.textValue);
    },
    FileTooLarge() {
      return this.input?.file?.size > 50000000;
    }
  }
};
</script>

<style scoped>
/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

/* Controls styling for the card component in
the main container. Shows survey card */
.main-card {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px 10px 10px 10px !important;
  height: auto;
  min-height: 50vh;
}

/* Inner content for main card so it has smaller margins when on mobile*/
.main-card-content {
  min-width: 60%;
  height: 100%;
  min-height: 60vh;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media screen and (min-width: 960px) {
  .main-card-content {
    margin-left: 10%;
    margin-right: 10%;
  }
}
@media screen and (min-width: 451px) {
  .main-card-content {
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 450px) {
  .main-card-content {
    margin-left: 0px;
    margin-right: 0px;
  }
}
</style>
