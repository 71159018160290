import axios from "../axios";

const baseUrl =
  (process.env.VUE_APP_DEV_LOCAL && process.env.VUE_APP_FORM_URL) ??
  `${process.env.VUE_APP_WHISTLE_API}/form-service`;

class FormService {
  // V2
  getFormsV2(formId = undefined, clientId = undefined, params = {}) {
    return axios
      .get(`${baseUrl}/v2/form${formId ? `/${formId}` : ``}`, {
        params: { ...params, clientId }
      })
      .then(r => {
        return r?.data;
      });
  } // end getFormsV2

  createFormSubmissionV2(formId, body = {}, params = {}) {
    return axios
      .post(`${baseUrl}/v2/form-submission/${formId}`, body, { params })
      .then(r => {
        return r?.data;
      });
  } // end createFormSubmissionV2

  createFormSubmissionFileUploadV2(formSubmissionId, body = {}, params = {}) {
    return axios
      .post(`${baseUrl}/v2/form-submission/${formSubmissionId}/file`, body, {
        params
      })
      .then(r => {
        return r?.data;
      });
  } // end createFormSubmissionFileUploadV2

  getFormSubmissionFileV2(formSubmissionId, params = {}) {
    return axios
      .get(`${baseUrl}/v2/form-submission/${formSubmissionId}/file`, {
        params: { ...params }
      })
      .then(r => {
        return r?.data;
      });
  } // end getFormSubmissionFileV2
}

export default new FormService();
