<template>
  <v-dialog v-model="display" :persistent="persistent" width="500">
    <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
      <div
        class="d-flex align-center mx-2 mb-2"
        :class="{ 'justify-start': !centerText, 'justify-center': centerText }"
      >
        <v-icon color="error" x-large class="exit-warning-icon mr-2"
          >mdi-alert</v-icon
        >
        <v-card-title class="word-break align-text-left exit-warning-text">
          {{ title }}
        </v-card-title>
      </div>
      <v-card-subtitle class="word-break mb-3" v-if="subtitle">{{
        subtitle
      }}</v-card-subtitle>
      <div
        class="d-flex justify-center align-center flex-wrap"
        :class="{
          'flex-column': $vuetify.breakpoint.xs
        }"
      >
        <v-btn
          color="brandCyan"
          @click="clickButton1"
          outlined
          depressed
          :width="button1Width"
          :loading="button1Loading"
          class="white--text mb-4 mx-3"
          v-if="button1Text"
          >{{ button1Text }}</v-btn
        >
        <!-- <v-spacer /> -->
        <v-btn
          color="brandCyan"
          class="white--text mb-4 mx-3"
          @click="clickButton2"
          :width="button2Width"
          :loading="button2Loading"
          depressed
          v-if="button2Text"
          >{{ button2Text }}</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ErrorDialog",
  components: {},
  props: {
    title: String,
    subtitle: String,
    button1Text: String,
    button2Text: String,
    centerText: {
      type: Boolean,
      default: false
    },
    button1Width: {
      type: String,
      default: "170"
    },
    button2Width: {
      type: String,
      default: "170"
    },
    persistent: {
      type: Boolean,
      default: false
    },
    button1Loading: {
      type: Boolean,
      default: false
    },
    button2Loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      display: true
    };
  },
  created() {
    console.log("Created ErrorDialog");
  },

  methods: {
    clickButton1() {
      this.$emit("button1");
    },
    clickButton2() {
      this.$emit("button2");
    }
  },
  computed: {},
  watch: {
    display: function(newVal) {
      if (!newVal) this.$emit("close");
    }
  }
};
</script>

<style></style>
